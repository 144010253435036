import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import post1 from "../assets/img/blog/ocupacional_promedic.png"
import post2 from "../assets/img/blog/nom30_promedic.png"
import post3 from "../assets/img/blog/nom35_promedic.png"
import post4 from "../assets/img/blog/accidentes_promedic.png"
import { Link } from "react-router-dom"

export default function Posts() {
    return (
        <>
            <div className="posts-container">
                <h2 className="text-center font-bold text-[#1f7b57] text-4xl mt-7">Blog</h2>
                <Swiper
                    spaceBetween={30}
                    breakpoints={{
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        }
                    }}
                    className="mySwiper p-10 w-10/12 mx-auto"
                >
                    <SwiperSlide>
                    <div className="post-container">
                        <div className="container-image-post">
                            <Link to="cultura-salud-ocupacional"><img className="post-image" src={post1} alt="post" /></Link>
                        </div>
                        <div className="post-info">      
                            <h5 className="post-by mt-2">Escrito por: <span className="text-[#1f7b57] font-bold">ProMedic</span> | 21 de Agosto 2022</h5>
                        </div>
                        <Link to="cultura-salud-ocupacional"><h2 className="post-heading text-lg font-bold">Cultura en Salud Ocupacional</h2></Link>
                        <p className="post-extract text-sm text-justify">La salud ocupacional se encarga de promover y mantener el más alto grado de bienestar físico, mental y social de todos los trabajadores.</p>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="post-container">
                        <div className="container-image-post">
                            <Link to="nom-30"><img className="post-image" src={post2} alt="post" /></Link>
                        </div>
                        <div className="post-info">      
                            <h5 className="post-by mt-2">Escrito por: <span className="text-[#1f7b57] font-bold">ProMedic</span> | 24 de Agosto 2022</h5>
                        </div>
                        <Link to="nom-30"><h2 className="post-heading text-lg font-bold">¿A qué se refiere la NOM-030?</h2></Link>
                        <p className="post-extract text-sm text-justify">La salud ocupacional se encarga de promover y mantener el más alto grado de bienestar físico, mental y social de todos los trabajadores.</p>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="post-container">
                        <div className="container-image-post">
                            <Link to="factores-riesgo-psicosocial"><img className="post-image" src={post3} alt="post" /></Link>
                        </div>
                        <div className="post-info">      
                            <h5 className="post-by mt-2">Escrito por: <span className="text-[#1f7b57] font-bold">ProMedic</span> | 27 de Agosto 2022</h5>
                        </div>
                        <Link to="factores-riesgo-psicosocial"><h2 className="post-heading text-lg font-bold">¿Qué son los factores de riesgo psicosocial?</h2></Link>
                        <p className="post-extract text-sm text-justify">La NOM 035, la norma mexicana que atiende los riesgos psicológicos y sociales provocados por el trabajo.</p>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="post-container">
                        <div className="container-image-post">
                            <Link to="principales-accidentes-en-trabajo"><img className="post-image" src={post4} alt="post" /></Link>
                        </div>
                        <div className="post-info">      
                            <h5 className="post-by mt-2">Escrito por: <span className="text-[#1f7b57] font-bold">ProMedic</span> | 30 de Agosto 2022</h5>
                        </div>
                        <Link to="principales-accidentes-en-trabajo"><h2 className="post-heading text-lg font-bold">¿Cuáles son los principales accidentes en el trabajo?</h2></Link>
                        <p className="post-extract text-sm text-justify">En México se registra un promedio de 1,100 accidentes en el trabajo al día. La mayoría de estos eventos suceden debido a los distintos riesgos a los cuales se enfrentan los trabajadores.</p>
                    </div>
                    </SwiperSlide>
                </Swiper>
                {/* <Link to="cultura-salud-ocupacional">Ver más artículos</Link> */}
            </div>
        </>
    )
}