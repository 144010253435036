import post from "../assets/img/blog/nom35_promedic.png"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet';

export default function RiesgoPsicosocial() {
    return(
        <>
        <Helmet>
        <title>ProMedic Blog - ¿Qué son los factores de riesgo psicosocial?</title>
        <meta name="description" content="La NOM 035, la norma mexicana que atiende los riesgos psicológicos y sociales provocados por el trabajo." />
        </Helmet> 
        <div className="wrapper w-11/12 md:w-10/12 mx-auto my-5 md:my-16 md:flex md:justify-between">
            <div class="main w-full md:w-[60%]">
                <img src={post} alt="" />
                <h1 className="title text-3xl font-bold my-3">¿Qué son los factores de riesgo psicosocial?</h1>
                <p className="extract text-lg text-gray-700">La NOM 035, la norma mexicana que atiende los riesgos psicológicos y sociales provocados por el trabajo.</p>
                <p className="body-post mt-10 text-gray-700">
                Hoy hablaremos de la NOM 035, la norma mexicana que atiende los riesgos psicológicos y sociales provocados por el trabajo. De acuerdo con la NOM-035-STPS-2018, Factores de riesgo psicosocial en el trabajo-Identificación, análisis y prevención. Tiene como objetivo establecer los elementos para identificar, analizar y prevenir los factores de riesgo psicosocial, así como para promover un entorno organizacional favorable en los centros de trabajo. <br /><br />
                Su campo de aplicación rige en todo el territorio nacional y en todos los centros de trabajo y se dispone de acuerdo con el número de trabajadores. <br /><br />
                Por consiguiente, contiene 3 niveles:
                <ul>
                    <li>Centros de trabajo donde laboran hasta 15 trabajadores;</li>
                    <li>Centros de trabajo donde laboran entre 16 y 50 trabajadores, y</li>
                    <li>Centros de trabajo donde laboran más de 50 trabajadores.</li>
                </ul>
                <br />
                A cada nivel le corresponden distintas disposiciones que deberán ser cumplidas.
                <br />
                <br />
                Obligaciones generales para la Empresa:
                <br />
                <br />
                Según el número de colaboradores, aplicarán unas u otras disposiciones, por lo que cada negocio y/o empresa debe reconocer a qué categoría pertenece y cumplir con las medidas de la Norma 035 que debe aplicar, ya que no todas las disposiciones aplican a todas las empresas. 
                <br />
                <br />
                Entre las obligaciones que marca la norma, se encuentran las siguientes: 
                <ul className="list-disc pl-10">
                    <li>Establecer y difundir una política de prevención de factores de riesgo psicosocial.</li>
                    <li>Adoptar medidas preventivas y controlar factores de riesgo psicosocial.</li>
                    <li>Identificar y canalizar para su atención a trabajadores expuestos a acontecimientos traumáticos severos.</li>
                    <li>Identificar factores de riesgo psicosocial.</li>
                    <li>Evaluar el entorno organizacional.</li>
                    <li>Tomar medidas y acciones de control.</li>
                    <li>Practicar exámenes médicos y evaluaciones a trabajadores expuestos a factores de riesgo.</li>
                    <li>Difundir un resumen de los resultados de los cuestionarios. </li>
                    <li>Informar sobre las medidas de prevención, las medidas implementadas y los mecanismos para reportar malas prácticas, así como de los planes de acción en caso de que aplique.</li>
                    <li>Toma de registros y manejo de evidencias.</li>
                </ul>
                <br />
                Obligaciones Generales para los empleados:
                <ul className="list-disc pl-10">
                    <li>Aplicar las medidas de prevención y de control dispuestas en la norma y/o por el patrón, con el fin de contribuir a un ambiente laboral propicio, libre de violencia laboral.</li>
                    <li>Acudir a la aplicación de los exámenes médicos y evaluaciones psicológicas, determinadas en la normatividad de la Secretaría de Salud y/o la Secretaría del Trabajo y Previsión Social, y/o los que indique la institución de seguridad social o privada, o el médico o psicólogo o psiquiatra del centro de trabajo o de la empresa.</li>
                    <li>Asistir a los eventos de información promocionados por el patrón.</li>
                    <li>Informar sobre prácticas opuestas al entorno organizacional favorable y denunciar actos de violencia laboral, utilizando los mecanismos que establezca el patrón y/o a través de la Comisión de Seguridad e Higiene.</li>
                    <li>Colaborar en la detección de riesgos psicosociales y en la evaluación del entorno organizacional.</li>
                    <li>Evitar realizar prácticas contrarias al entorno organizacional favorable y actos de violencia laboral.</li>

                </ul>
                <br />
                En Promedic cuidamos la integridad física y emocional de los trabajadores, con programas especializados en cada rama laboral. Realiza con nosotros un diagnóstico del cumplimiento de las Normas Oficiales Mexicanas y obtén un programa de Salud Ocupacional de acuerdo con los requerimientos de tu empresa.
                <br />
                <br />

                Referencias:
                <Link to="https://www.gob.mx/stps/articulos/norma-oficial-mexicana-nom-035-stps-2018-factores-de-riesgo-psicosocial-en-el-trabajo-identificacion-analisis-y-prevencion#:~:text=La%20NOM%20035%20tiene%20como,en%20los%20centros%20de%20trabajo">https://www.gob.mx/stps/articulos/norma-oficial-mexicana-nom-035-stps-2018-factores-de-riesgo-psicosocial-en-el-trabajo-identificacion-analisis-y-prevencion#:~:text=La%20NOM%20035%20tiene%20como,en%20los%20centros%20de%20trabajo</Link>
                </p>
            </div>
            <div className="sidebar w-full md:w-[30%] mb-16 h-[25vh] mx-auto">
                <button className="text-right my-5 w-full text-green-900 text-xl"><Link to="/">← Regresar a Inicio</Link></button>
                <h2 className="text-right text-xl font-bold">Artículos</h2>
                <ul className="my-3 text-green-800 text-lg text-right leading-8">
                    <li>
                        <Link to="/cultura-salud-ocupacional" className="hover:font-bold ease-out duration-120">Cultura en Salud Ocupacional</Link>
                    </li>
                    <li>
                        <Link to="/nom-30" className="hover:font-bold ease-out duration-120">¿A qué se refiere la NOM-030?</Link>
                    </li>
                    <li>
                        <Link to="/factores-riesgo-psicosocial" className="hover:font-bold ease-out duration-120">¿Qué son los factores de riesgo psicosocial?</Link>
                    </li>
                    <li>
                        <Link to="/principales-accidentes-en-trabajo" className="hover:font-bold ease-out duration-120">¿Cuáles son los principales accidentes en el trabajo?</Link>
                    </li>
                </ul>
            </div>
        </div>
        </>
    )
}