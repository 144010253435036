import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import people from "../assets/img/vector1.svg"
import lab from "../assets/img/vector2.svg"
import badge from "../assets/img/vector3.svg"

// import required modules
import { Autoplay, Pagination } from "swiper";

export default function SliderComponent() {
    return (
        <div>
            <div className="md:block mt-[2%]">
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 8000,
                        disableOnInteraction: true,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper w-10/12 mx-auto md:pb-20 content-center"
                >
                    <SwiperSlide className="py-14 md:py-0">
                        <div className="grid grid-cols-1 items-center w-12/12 md:w-9/12 md:mx-auto md:grid-cols-2">
                            <div className="text-center">
                                <img src={people} alt="" className="mx-auto" />
                            </div>
                            <div className="text-center">
                                <h3 className="text-2xl mt-3 font-bold">¿Quiénes Somos?</h3>
                                <p>Pioneros en promover el cumplimiento de la salud ocupacional a través de una orientación por profesionales en medicina laboral y aplicaciones tecnológicas de alto impacto; adaptando nuestro sistema de gestión con base a las necesidades normativas y requerimientos de las empresas o giros industriales con un enfoque especial en la prevención primaria de los factores de riesgo en el entorno laboral de los colaboradores.</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="py-14 md:py-0">
                        <div className="grid grid-cols-1 items-center w-100 md:w-10/12 md:grid-cols-2 content-center">
                            <div className="text-right">
                                <img src={lab} alt="" className="mx-auto" />
                            </div>
                            <div className="text-center">
                                <h3 className="text-2xl mt-3 font-bold">Equipo de Expertos</h3>
                                <p>Miembros activos de las principales sociedades en el campo y certificados en estándares internacionales, en actualización permanente en área clínica, tecnológica y seguridad, lo que nos permite integrar todos nuestros servicios orientados en el máximo beneficio de nuestros clientes y sus trabajadores.</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="py-14 md:py-0">
                        <div className="grid grid-cols-1 items-center w-100 md:w-10/12 md:grid-cols-2">
                            <div className="text-right">
                                <img src={badge} alt="" className="mx-auto" />
                            </div>
                            <div className="text-center">
                                <h3 className="text-2xl mt-3 font-bold">Nuestros beneficios de servicio</h3>
                                <ul>
                                    <li>Cumplimiento normativo en Salud Ocupacional.</li>
                                    <li>Reducción de índices por presentismo, ausentismo y rotación.</li>
                                    <li>Reducción de bajas laborales por enfermedad.</li>
                                    <li>Programas de prevención mitigando los riesgos en la salud de la fuerza laboral.</li>
                                    <li>Disminuir costos que implican los accidentes y enfermedades de los colaboradores que merman la productividad en las empresas.</li>
                                    <li>Contratación de colaboradores con salud óptima para el desempeño de actividades.</li>
                                </ul>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
}