import { useEffect } from "react"

export default function AvisoPrivacidad() {

    useEffect(() => {
        document.title = "ProMedic - Aviso de Privacidad"
    }, [])

    return(
        <>
        <div className="container p-20">
        <h1 className="my-5 text-center font-bold text-xl">Aviso de Privacidad Integral</h1>
        <ul className="font-bold text-[#1f7b57]">
        <li><a  href="#clientes">Aviso de Privacidad Promedic (Clientes)</a></li>
        <li><a  href="#call-center">Aviso de Privacidad Promedic (Call Center)</a></li>
        <li><a  href="#redes-sociales">Aviso de Privacidad Promedic (Redes Sociales)</a></li>
        <li><a  href="#contacto">Aviso de Privacidad Promedic (Contacto)</a></li>
        <li><a  href="#proveedores">Aviso de Privacidad Promedic (Proveedores)</a></li>
        <li><a  href="#visitantes-y-recepcion">Aviso de Privacidad Promedic (Visitantes y Recepción)</a></li>
        <li><a  href="#seguridad-y-vigilancia">Aviso de Privacidad Promedic (Seguridad y Vigilancia)</a></li>
        <li><a  href="#bolsa-de-trabajo">Aviso de Privacidad Promedic (Bolsa de Trabajo)</a></li>
        <li><a  href="#empleados">Aviso de Privacidad Promedic (Empleados)</a></li>
        </ul>
        <br />
        <br />
        <p align="center" id="clientes"><strong>AVISO DE PRIVACIDAD (CLIENTES)</strong></p>
        <p align="center"><strong>&nbsp;</strong></p>
        <p>M&eacute;dica Profesional Integral, S.A. de C.V. empresa conocida comercialmente como Promedic&reg;, con domicilio en calle Jimenez 465, Piso 9, Colonia Centro, San Pedro Garza Garc&iacute;a, Nuevo Le&oacute;n, C&oacute;digo Postal 66230, y portal de internet <a href="https://promedic.com.mx/">https://promedic.com.mx/</a> es el responsable del uso, tratamiento y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Para qu&eacute; fines utilizaremos sus datos personales?</strong></p>
        <p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita: I) Registro de Clientes para proveer los servicios que haya contratado;</p>
        <p>II) Procesamiento de muestras biol&oacute;gicas, gabinete (imagen) y/o estudios auxiliares al diagn&oacute;stico m&eacute;dico, hasta la generaci&oacute;n y env&iacute;o de sus resultados. La generaci&oacute;n de resultados se realiza a trav&eacute;s de las diferentes plataformas que le indique Promedic&reg;, &eacute;l envi&oacute; de resultados a trav&eacute;s de cualquier medio digital y/o impresos en sucursal ser&aacute; &uacute;nicamente a quien usted indique en la orden de servicio y/o comprobante de pago, pudiendo ser, familiares, m&eacute;dico tratante y/o tercero autorizado;</p>
        <p>III) Procesamiento de pago, generaci&oacute;n y envi&oacute; de facturas;</p>
        <p>IV) Dar cumplimiento a las obligaciones legales, fiscales y requerimientos de la autoridad competente;</p>
        <p>V) Dar respuesta a sus dudas o comentarios y/o proporcionarle la informaci&oacute;n que solicite;</p>
        <p>VI) Informar alguna modificaci&oacute;n en los servicios contratados;</p>
        <p>VII) Enviar informaci&oacute;n de promociones sobre nuestros productos o servicios relacionados a los servicios contratados;</p>
        <p>VIII) Proteger la Propiedad, derechos y personal del responsable y;</p>
        <p>IX) Otorgar Seguridad en nuestras instalaciones.</p>
        <p>&nbsp;</p>
        <p>De manera adicional, utilizaremos su informaci&oacute;n personal para las siguientes finalidades secundarias que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atenci&oacute;n:</p>
        <p>I) Encuestas y evaluaciones de calidad sobre la prestaci&oacute;n de nuestros servicios;</p>
        <p>II) Realizar estudios de mercado y h&aacute;bitos de consumo; y</p>
        <p>III) Enviar publicidad acerca de nuestros diferentes productos o servicios y/o promociones a trav&eacute;s de medios impresos y/o digitales y/o hacer llamadas telef&oacute;nicas con fines publicitarios, mercadotecnia o publicitarios.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Qu&eacute; datos personales utilizaremos para estos fines?</strong></p>
        <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>
        <p>I) Datos de identificaci&oacute;n: pudiendo ser nombre, fecha de nacimiento y/o edad;</p>
        <p>II) Datos de contacto: pudiendo ser direcci&oacute;n, correo electr&oacute;nico, tel&eacute;fono fijo y/o trabajo y/o m&oacute;vil;</p>
        <p>III) Nombre de personas a quien se autoriza la entrega resultados, pudiendo ser: familiares, amigos y/o m&eacute;dico tratante.</p>
        <p>IV) Datos fiscales: pudiendo ser nombre, Registro Federal de Contribuyentes, Direcci&oacute;n fiscal, uso del comprobante fiscal digital (CFDI), forma de pago y/o correo electr&oacute;nico;</p>
        <p>V) Datos laborales: pudiendo ser patr&oacute;n o empleador y/o n&uacute;mero de empleado;</p>
        <p>VI) Datos biom&eacute;tricos: pudiendo ser huellas dactilares; y</p>
        <p>VII) Datos de Imagen y voz: pudiendo ser a trav&eacute;s de videovigilancia en tiempo real.</p>
        <p>&nbsp;</p>
        <p>Adem&aacute;s de los datos personales mencionados anteriormente, para las finalidades informadas en el presente aviso de privacidad utilizaremos los siguientes datos personales considerados como sensibles, que requieren de especial protecci&oacute;n:</p>
        <p>I) Informaci&oacute;n financiera y m&eacute;todo de pago: pudiendo ser forma de pago y/o datos bancarios;</p>
        <p>II) Datos de salud: &uacute;nicamente en caso de que sea un aspecto relevante en la prestaci&oacute;n del servicio contratado;</p>
        <p>III) Datos sobre vida sexual: &uacute;nicamente en caso de que sea un aspecto relevante en la prestaci&oacute;n del servicio contratado.</p>
        <p>&nbsp;</p>
        <p>La descripci&oacute;n de los datos personales que ser&aacute;n utilizados y se encuentran enumerados en los p&aacute;rrafos anteriores, son de car&aacute;cter enunciativo mas no limitativo, pudiendo limitarse o ampliarse de acuerdo al servicio contratado.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Con qui&eacute;n compartimos su informaci&oacute;n personal y para qu&eacute; fines?</strong></p>
        <p>Le informamos que sus datos personales son compartidos dentro del pa&iacute;s con las siguientes personas, empresas, organizaciones o autoridades distintas a nosotros, para los siguientes fines: Sociedades Controladoras, filiales y/o subsidiarias:&nbsp; con la finalidad de resguardo Centralizado de la Informaci&oacute;n, control de altas, bajas y/o modificaciones, Informe de actividades y Registro de Clientes; Sociedad M&eacute;dicas Certificadas dedicadas a la Investigaci&oacute;n, Autoridades, Organismos o entidades gubernamentales: Para el cumplimiento a las obligaciones se&ntilde;aladas en la legislaci&oacute;n aplicable en Materia de Salud y/o cumplimiento a requerimientos de la misma autoridad y/o investigaci&oacute;n en Materia de Salud; Cobranza: Para la gesti&oacute;n de cobranza de cuentas por cobrar en el &aacute;mbito judicial o extrajudicial y; Clientes y/o Proveedores: Para el cumplimiento de obligaciones contractuales y comerciales entre el responsable y sus clientes y/o proveedores.</p>
        <p>&nbsp;</p>
        <p>Adicional a lo anterior, le informamos que sus datos personales pueden ser compartidos fuera del pa&iacute;s con Proveedores dedicados al resguardo y/o almacenamiento de la informaci&oacute;n, lo anterior para garantizar la seguridad y protecci&oacute;n de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>El titular reconoce y acepta que Promedic&reg; no requiere autorizaci&oacute;n ni confirmaci&oacute;n del Titular para realizar Transferencia de Datos Personales nacionales o internacionales en los casos previstos en el Art&iacute;culo 37 de la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares.</p>
        <p>&nbsp;</p>
        <p>En todo momento Promedic&reg; realizara las acciones que considere pertinentes con la finalidad de garantizar la protecci&oacute;n y resguardo de sus datos personales.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</strong></p>
        <p>Usted tiene derecho a conocer qu&eacute; datos personales tenemos de usted, para qu&eacute; los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la correcci&oacute;n de su informaci&oacute;n personal en caso de que est&eacute; desactualizada, sea inexacta o incompleta (Rectificaci&oacute;n); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no est&aacute; siendo utilizada adecuadamente (Cancelaci&oacute;n); as&iacute; como oponerse al uso de sus datos personales para fines espec&iacute;ficos (Oposici&oacute;n). Estos derechos se conocen como derechos ARCO.</p>
        <p>&nbsp;</p>
        <p>Para el ejercicio de cualquiera de los derechos ARCO, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntarse la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copia de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Tipo de Derecho ARCO que se pretende ejercer. (Acceso, Rectificaci&oacute;n, Cancelaci&oacute;n u Oposici&oacute;n); IV) Una descripci&oacute;n clara y precisa de lo que pretende ejercer sobre sus Datos Personales; V) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales; VI) En caso de solicitar una rectificaci&oacute;n de datos personales, deber&aacute; de indicar tambi&eacute;n, las modificaciones a realizarse y aportar la documentaci&oacute;n que sustente su petici&oacute;n (donde los datos est&eacute;n correctos) y; VII) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con al Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>Usted puede revocar su consentimiento para el uso de sus datos personales</strong></p>
        <p>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales. Asimismo, usted deber&aacute; considerar que para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando el servicio que nos solicit&oacute;, o la conclusi&oacute;n de su relaci&oacute;n con nosotros.</p>
        <p>&nbsp;</p>
        <p>Para revocar su consentimiento, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntar la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copias de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Una descripci&oacute;n clara y precisa de los datos personales respecto de los cuales busca revocar su consentimiento; IV) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales y; V) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Su solicitud ser&aacute; contestada mediante correo electr&oacute;nico por parte del Departamento de Datos Personales en un plazo m&aacute;ximo de 20 (veinte) d&iacute;as h&aacute;biles contados desde el d&iacute;a en que se haya recibido. En caso de que la solicitud se conteste de manera positiva o procedente, la petici&oacute;n se podr&aacute; hacer efectiva en un plazo m&aacute;ximo de 15 (quince) d&iacute;as h&aacute;biles. Los plazos se&ntilde;alados en este p&aacute;rrafo podr&aacute;n prorrogarse por el responsable en una sola ocasi&oacute;n y por un periodo igual en caso de ser necesario.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con el Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede limitar el uso o divulgaci&oacute;n de su informaci&oacute;n personal?</strong></p>
        <p>Para limitar el uso y/o divulgaci&oacute;n de sus Datos Personales, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p><strong>El uso de tecnolog&iacute;as de rastreo en nuestro portal de internet</strong></p>
        <p>Le informamos que en nuestra p&aacute;gina de internet utilizamos cookies, web beacons u otras tecnolog&iacute;as, a trav&eacute;s de las cuales es posible monitorear su comportamiento como usuario de internet, as&iacute; como brindarle un mejor servicio y experiencia al navegar en nuestra p&aacute;gina.</p>
        <p>&nbsp;</p>
        <p>Los datos que se obtienen con el uso de estas tecnolog&iacute;as ser&aacute;n tratados &uacute;nicamente para personalizar y mejorar el contenido de nuestro sitio de internet, evaluar el uso del mismo, y simplificar su experiencia cuando vuelva a visitarlo.</p>
        <p>&nbsp;</p>
        <p>Es posible deshabilitar estas tecnolog&iacute;as de seguimiento en todo tipo de buscadores; sin embargo, para ejemplificar, se se&ntilde;alan a continuaci&oacute;n los pasos que hay que seguir para deshabilitarlas en Google Chrome: Ir al men&uacute; &ldquo;Herramientas&rdquo;; dar clic en &ldquo;borrar datos de navegaci&oacute;n&rdquo;, seleccionar la opci&oacute;n &ldquo;eliminar cookies y otros datos de sitios y de complementos&rdquo;, dar clic en &ldquo;aceptar&rdquo;. Al deshabilitar o bloquear estos mecanismos se puede ocasionar que el sitio no funcione correctamente.&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>Enlaces de otros sitios de Internet.</strong></p>
        <p>Nuestro sitio de internet puede contener, para su conveniencia, enlaces a otros sitios en Internet que no pertenecen a Promedic&reg;. Promedic&reg; no ha revisado los Avisos de Privacidad de dichos sitios, por lo que no garantiza ni se hace responsable del contenido de dichos, ni del tratamiento que tales sitios den a sus datos personales. Por tanto, le sugerimos que lea cuidadosamente los Avisos de Privacidad de cada uno de los sitios que visita en Internet.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede conocer los cambios en este aviso de privacidad?</strong></p>
        <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras pr&aacute;cticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas</p>
        <p>&nbsp;</p>
        <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a trav&eacute;s del sitio: <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p><strong>Su consentimiento para el tratamiento de sus datos personales</strong></p>
        <p>Le&iacute;do el presente documento y entendiendo el alcance del mismo otorg&oacute; mi consentimiento para que Promedic&reg; de tratamiento a mis datos personales de conformidad con lo establecido en el presente Aviso de Privacidad.</p>
        <p>&nbsp;</p>
        <p>Acepto y reconozco que cualquier modificaci&oacute;n al presente Aviso de Privacidad se me dar&aacute; aviso a trav&eacute;s del sitio web <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p align="right">&nbsp;</p>
        <p align="right">&Uacute;ltima actualizaci&oacute;n: 13 de junio de 2022.</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p align="center"><strong>AVISO DE PRIVACIDAD (Clientes)</strong></p>
        <p align="center"><strong>&nbsp;</strong></p>
        <p>M&eacute;dica Profesional Integral, S.A. de C.V. empresa conocida comercialmente como Promedic&reg;, con domicilio en calle Jimenez 465, Piso 9, Colonia Centro, San Pedro Garza Garc&iacute;a, Nuevo Le&oacute;n, C&oacute;digo Postal 66230, es el responsable del uso, tratamiento y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Para qu&eacute; fines utilizaremos sus datos personales? </strong>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita: I) Registro de Clientes para proveer los servicios que haya contratado; II) Procesamiento de muestras biol&oacute;gicas, gabinete (imagen) y/o estudios auxiliares al diagn&oacute;stico m&eacute;dico, hasta la generaci&oacute;n y env&iacute;o de sus resultados. La generaci&oacute;n de resultados se realiza a trav&eacute;s de las diferentes plataformas que le indique Promedic&reg;, &eacute;l envi&oacute; de resultados a trav&eacute;s de cualquier medio digital y/o impresos en sucursal ser&aacute; &uacute;nicamente a quien usted indique en la orden de servicio y/o comprobante de pago, pudiendo ser, familiares, m&eacute;dico tratante y/o tercero autorizado; III) Procesamiento de pago, generaci&oacute;n y envi&oacute; de facturas; IV) Dar cumplimiento a las obligaciones legales, fiscales y requerimientos de la autoridad competente; V) Dar respuesta a sus dudas o comentarios y/o proporcionarle la informaci&oacute;n que solicite; VI) Informar alguna modificaci&oacute;n en los servicios contratados; VII) Enviar informaci&oacute;n de promociones sobre nuestros productos o servicios relacionados a los servicios contratados; VIII) Proteger la Propiedad, derechos y personal del responsable; y IX) Otorgar Seguridad en nuestras instalaciones.</p>
        <p>&nbsp;</p>
        <p>De manera adicional, utilizaremos su informaci&oacute;n personal para las siguientes finalidades secundarias que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atenci&oacute;n: I) Encuestas y evaluaciones de calidad sobre la prestaci&oacute;n de nuestros servicios; II) Realizar estudios de mercado y h&aacute;bitos de consumo; y III) Enviar publicidad acerca de nuestros diferentes productos o servicios y/o promociones a trav&eacute;s de medios impresos y/o digitales y/o hacer llamadas telef&oacute;nicas con fines publicitarios, mercadotecnia o publicitarios.</p>
        <p>&nbsp;</p>
        <p>El titular reconoce y acepta que Promedic&reg;, no requiere autorizaci&oacute;n ni confirmaci&oacute;n del Titular para realizar Transferencia de Datos Personales nacionales o internacionales en los casos previstos en el Art&iacute;culo 37 de la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares. En todo momento Promedic&reg;, realizara las acciones que considere pertinentes con la finalidad de garantizar la protecci&oacute;n y resguardo de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>Le&iacute;do el presente documento y entendiendo el alcance del mismo, otorg&oacute; mi consentimiento para que Promedic&reg;, de tratamiento a mis datos personales de conformidad con lo establecido en el Aviso de Privacidad Integral que se puso a mi disposici&oacute;n de manera impresa y digital, aceptando y reconociendo que cualquier modificaci&oacute;n al Aviso de Privacidad Integral se me dar&aacute; aviso a trav&eacute;s del sitio web <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p align="right">&nbsp;</p>
        <p align="right">&Uacute;ltima actualizaci&oacute;n: 13 de junio de 2022.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p align="center" id="call-center"><strong>AVISO DE PRIVACIDAD (Call Center)</strong></p>
        <p align="center"><strong>&nbsp;</strong></p>
        <p>M&eacute;dica Profesional Integral, S.A. de C.V. empresa conocida comercialmente como Promedic&reg;, con domicilio en calle Jimenez 465, Piso 9, Colonia Centro, San Pedro Garza Garc&iacute;a, Nuevo Le&oacute;n, C&oacute;digo Postal 66230, y portal de internet <a href="https://promedic.com.mx/">https://promedic.com.mx/</a> es el responsable del uso, tratamiento y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Para qu&eacute; fines utilizaremos sus datos personales?</strong></p>
        <p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:</p>
        <p>I) Registro de Clientes para proveer los servicios que haya contratado;</p>
        <p>II) Agendar citas para brindar los servicios contratos;</p>
        <p>III) Dar informaci&oacute;n y recomendaciones acerca de los servicios contratados previos a la prestaci&oacute;n del servicio;</p>
        <p>IV) Procesamiento de pago, generaci&oacute;n y envi&oacute; de facturas;</p>
        <p>V) Dar cumplimiento a las obligaciones legales, fiscales y requerimientos de la autoridad competente;</p>
        <p>VI) Dar respuesta a sus dudas o comentarios y/o proporcionarle la informaci&oacute;n que solicite;</p>
        <p>VII) Informar alguna modificaci&oacute;n en los servicios contratados;</p>
        <p>VIII) Enviar informaci&oacute;n de promociones sobre nuestros productos o servicios relacionados a los servicios contratados;</p>
        <p>&nbsp;</p>
        <p>De manera adicional, utilizaremos su informaci&oacute;n personal para las siguientes finalidades secundarias que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atenci&oacute;n:</p>
        <p>I) Encuestas y evaluaciones de calidad sobre la prestaci&oacute;n de nuestros servicios;</p>
        <p>II) Realizar estudios de mercado y h&aacute;bitos de consumo; y</p>
        <p>III) Enviar publicidad acerca de nuestros diferentes productos o servicios y/o promociones a trav&eacute;s de medios impresos y/o digitales y/o hacer llamadas telef&oacute;nicas con fines publicitarios, mercadotecnia o publicitarios.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Qu&eacute; datos personales utilizaremos para estos fines?</strong></p>
        <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>
        <p>I) Datos de identificaci&oacute;n: pudiendo ser nombre, fecha de nacimiento y/o edad;</p>
        <p>II) Datos de contacto: pudiendo ser direcci&oacute;n, correo electr&oacute;nico, tel&eacute;fono fijo y/o trabajo y/o m&oacute;vil;</p>
        <p>III) Nombre de personas a quien se autoriza la entrega resultados, pudiendo ser: familiares, amigos y/o m&eacute;dico tratante.</p>
        <p>IV) Datos fiscales: pudiendo ser nombre, Registro Federal de Contribuyentes, Direcci&oacute;n fiscal, uso del comprobante fiscal digital (CFDI), forma de pago y/o correo electr&oacute;nico;</p>
        <p>V) Datos laborales: pudiendo ser patr&oacute;n o empleador y/o n&uacute;mero de empleado;</p>
        <p>VI) Datos Voz: pudiendo ser a trav&eacute;s de llamadas telef&oacute;nicas.</p>
        <p>&nbsp;</p>
        <p>Adem&aacute;s de los datos personales mencionados anteriormente, para las finalidades informadas en el presente aviso de privacidad utilizaremos los siguientes datos personales considerados como sensibles, que requieren de especial protecci&oacute;n:</p>
        <p>I) Informaci&oacute;n financiera y m&eacute;todo de pago: pudiendo ser forma de pago y/o datos bancarios;</p>
        <p>&nbsp;</p>
        <p>La descripci&oacute;n de los datos personales que ser&aacute;n utilizados y se encuentran enumerados en los p&aacute;rrafos anteriores, son de car&aacute;cter enunciativo mas no limitativo, pudiendo limitarse o ampliarse de acuerdo al servicio contratado.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Con qui&eacute;n compartimos su informaci&oacute;n personal y para qu&eacute; fines?</strong></p>
        <p>Le informamos que sus datos personales son compartidos dentro del pa&iacute;s con las siguientes personas, empresas, organizaciones o autoridades distintas a nosotros, para los siguientes fines: Sociedades Controladoras, filiales y/o subsidiarias:&nbsp; con la finalidad de resguardo Centralizado de la Informaci&oacute;n, control de altas, bajas y/o modificaciones, Informe de actividades y Registro de Clientes; Sociedad M&eacute;dicas Certificadas dedicadas a la Investigaci&oacute;n, Autoridades, Organismos o entidades gubernamentales: Para el cumplimiento a las obligaciones se&ntilde;aladas en la legislaci&oacute;n aplicable en Materia de Salud y/o cumplimiento a requerimientos de la misma autoridad y/o investigaci&oacute;n en Materia de Salud; Cobranza: Para la gesti&oacute;n de cobranza de cuentas por cobrar en el &aacute;mbito judicial o extrajudicial y; Clientes y/o Proveedores: Para el cumplimiento de obligaciones contractuales y comerciales entre el responsable y sus clientes y/o proveedores.</p>
        <p>&nbsp;</p>
        <p>Adicional a lo anterior, le informamos que sus datos personales pueden ser compartidos fuera del pa&iacute;s con Proveedores dedicados al resguardo y/o almacenamiento de la informaci&oacute;n, lo anterior para garantizar la seguridad y protecci&oacute;n de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>El titular reconoce y acepta que Promedic&reg; no requiere autorizaci&oacute;n ni confirmaci&oacute;n del Titular para realizar Transferencia de Datos Personales nacionales o internacionales en los casos previstos en el Art&iacute;culo 37 de la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares.</p>
        <p>&nbsp;</p>
        <p>En todo momento Promedic&reg; realizara las acciones que considere pertinentes con la finalidad de garantizar la protecci&oacute;n y resguardo de sus datos personales.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</strong></p>
        <p>Usted tiene derecho a conocer qu&eacute; datos personales tenemos de usted, para qu&eacute; los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la correcci&oacute;n de su informaci&oacute;n personal en caso de que est&eacute; desactualizada, sea inexacta o incompleta (Rectificaci&oacute;n); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no est&aacute; siendo utilizada adecuadamente (Cancelaci&oacute;n); as&iacute; como oponerse al uso de sus datos personales para fines espec&iacute;ficos (Oposici&oacute;n). Estos derechos se conocen como derechos ARCO.</p>
        <p>&nbsp;</p>
        <p>Para el ejercicio de cualquiera de los derechos ARCO, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntarse la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copia de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Tipo de Derecho ARCO que se pretende ejercer. (Acceso, Rectificaci&oacute;n, Cancelaci&oacute;n u Oposici&oacute;n); IV) Una descripci&oacute;n clara y precisa de lo que pretende ejercer sobre sus Datos Personales; V) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales; VI) En caso de solicitar una rectificaci&oacute;n de datos personales, deber&aacute; de indicar tambi&eacute;n, las modificaciones a realizarse y aportar la documentaci&oacute;n que sustente su petici&oacute;n (donde los datos est&eacute;n correctos) y; VII) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con al Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>Usted puede revocar su consentimiento para el uso de sus datos personales</strong></p>
        <p>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales. Asimismo, usted deber&aacute; considerar que para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando el servicio que nos solicit&oacute;, o la conclusi&oacute;n de su relaci&oacute;n con nosotros.</p>
        <p>&nbsp;</p>
        <p>Para revocar su consentimiento, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntar la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copias de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Una descripci&oacute;n clara y precisa de los datos personales respecto de los cuales busca revocar su consentimiento; IV) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales y; V) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Su solicitud ser&aacute; contestada mediante correo electr&oacute;nico por parte del Departamento de Datos Personales en un plazo m&aacute;ximo de 20 (veinte) d&iacute;as h&aacute;biles contados desde el d&iacute;a en que se haya recibido. En caso de que la solicitud se conteste de manera positiva o procedente, la petici&oacute;n se podr&aacute; hacer efectiva en un plazo m&aacute;ximo de 15 (quince) d&iacute;as h&aacute;biles. Los plazos se&ntilde;alados en este p&aacute;rrafo podr&aacute;n prorrogarse por el responsable en una sola ocasi&oacute;n y por un periodo igual en caso de ser necesario.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con el Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede limitar el uso o divulgaci&oacute;n de su informaci&oacute;n personal?</strong></p>
        <p>Para limitar el uso y/o divulgaci&oacute;n de sus Datos Personales, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p><strong>El uso de tecnolog&iacute;as de rastreo en nuestro portal de internet</strong></p>
        <p>Le informamos que en nuestra p&aacute;gina de internet utilizamos cookies, web beacons u otras tecnolog&iacute;as, a trav&eacute;s de las cuales es posible monitorear su comportamiento como usuario de internet, as&iacute; como brindarle un mejor servicio y experiencia al navegar en nuestra p&aacute;gina.</p>
        <p>&nbsp;</p>
        <p>Los datos que se obtienen con el uso de estas tecnolog&iacute;as ser&aacute;n tratados &uacute;nicamente para personalizar y mejorar el contenido de nuestro sitio de internet, evaluar el uso del mismo, y simplificar su experiencia cuando vuelva a visitarlo.</p>
        <p>&nbsp;</p>
        <p>Es posible deshabilitar estas tecnolog&iacute;as de seguimiento en todo tipo de buscadores; sin embargo, para ejemplificar, se se&ntilde;alan a continuaci&oacute;n los pasos que hay que seguir para deshabilitarlas en Google Chrome: Ir al men&uacute; &ldquo;Herramientas&rdquo;; dar clic en &ldquo;borrar datos de navegaci&oacute;n&rdquo;, seleccionar la opci&oacute;n &ldquo;eliminar cookies y otros datos de sitios y de complementos&rdquo;, dar clic en &ldquo;aceptar&rdquo;. Al deshabilitar o bloquear estos mecanismos se puede ocasionar que el sitio no funcione correctamente.&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>Enlaces de otros sitios de Internet.</strong></p>
        <p>Nuestro sitio de internet puede contener, para su conveniencia, enlaces a otros sitios en Internet que no pertenecen a Promedic&reg;. Promedic&reg; no ha revisado los Avisos de Privacidad de dichos sitios, por lo que no garantiza ni se hace responsable del contenido de dichos, ni del tratamiento que tales sitios den a sus datos personales. Por tanto, le sugerimos que lea cuidadosamente los Avisos de Privacidad de cada uno de los sitios que visita en Internet.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede conocer los cambios en este aviso de privacidad?</strong></p>
        <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras pr&aacute;cticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas</p>
        <p>&nbsp;</p>
        <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a trav&eacute;s del sitio: <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p><strong>Su consentimiento para el tratamiento de sus datos personales</strong></p>
        <p>Le&iacute;do el presente documento y entendiendo el alcance del mismo otorg&oacute; mi consentimiento para que Promedic&reg; de tratamiento a mis datos personales de conformidad con lo establecido en el presente Aviso de Privacidad.</p>
        <p>&nbsp;</p>
        <p>Acepto y reconozco que cualquier modificaci&oacute;n al presente Aviso de Privacidad se me dar&aacute; aviso a trav&eacute;s del sitio web <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p align="right">&nbsp;</p>
        <p align="right">&nbsp;</p>
        <p align="right">&Uacute;ltima actualizaci&oacute;n: 13 de junio de 2022.</p>
        <p>&nbsp;</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p align="center" id="redes-sociales"><strong>AVISO DE PRIVACIDAD (Redes Sociales)</strong></p>
        <p align="center"><strong>&nbsp;</strong></p>
        <p>M&eacute;dica Profesional Integral, S.A. de C.V. empresa conocida comercialmente como Promedic&reg;, con domicilio en calle Jimenez 465, Piso 9, Colonia Centro, San Pedro Garza Garc&iacute;a, Nuevo Le&oacute;n, C&oacute;digo Postal 66230, y portal de internet <a href="https://promedic.com.mx/">https://promedic.com.mx/</a> es el responsable del uso, tratamiento y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Para qu&eacute; fines utilizaremos sus datos personales? </strong></p>
        <p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son:</p>
        <p>I) Dar respuesta a sus dudas o comentarios y/o proporcionarle la informaci&oacute;n que solicite;</p>
        <p>II) Informar alg&uacute;n cambio en nuestros productos o servicios;</p>
        <p>III) Registro hist&oacute;rico y estad&iacute;stica de consultas y/o seguidores;</p>
        <p>IV) Administraci&oacute;n de Usuarios de Redes Sociales;</p>
        <p>V) Administraci&oacute;n de suscriptores a boletines;</p>
        <p>VI) Encuestas y evaluaciones de calidad sobre la prestaci&oacute;n de nuestros servicios;</p>
        <p>VII) Realizar estudios de mercado y h&aacute;bitos de consumo; y</p>
        <p>VIII) Enviar publicidad acerca de nuestros diferentes productos o servicios y/o promociones a trav&eacute;s de medios impresos y/o digitales y/o hacer llamadas telef&oacute;nicas con fines publicitarios, mercadotecnia o publicitarios.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Qu&eacute; datos personales utilizaremos para estos fines?</strong></p>
        <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>
        <p>I) Datos de Identificaci&oacute;n: pudiendo ser nombre, fecha de nacimiento y/o edad; y</p>
        <p>II) Datos de Contacto: pudiendo ser direcci&oacute;n, correo electr&oacute;nico, tel&eacute;fono fijo y/o trabajo y/o m&oacute;vil.</p>
        <p>&nbsp;</p>
        <p>La descripci&oacute;n de los datos personales que ser&aacute;n utilizados y se encuentran enumerados en los p&aacute;rrafos anteriores, son de car&aacute;cter enunciativo mas no limitativo, pudiendo limitarse o ampliarse de acuerdo al servicio contratado.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Con qui&eacute;n compartimos su informaci&oacute;n personal y para qu&eacute; fines?</strong></p>
        <p>Le informamos que sus datos personales son compartidos dentro del pa&iacute;s con las siguientes personas, empresas, organizaciones o autoridades distintas a nosotros, para los siguientes fines: Sociedades Controladoras, filiales y/o subsidiarias:&nbsp; con la finalidad de resguardo Centralizado de la Informaci&oacute;n, control de altas, bajas y/o modificaciones, Informe de actividades y Registro de Clientes; Sociedad M&eacute;dicas Certificadas dedicadas a la Investigaci&oacute;n, Autoridades, Organismos o entidades gubernamentales: Para el cumplimiento a las obligaciones se&ntilde;aladas en la legislaci&oacute;n aplicable en Materia de Salud y/o cumplimiento a requerimientos de la misma autoridad y/o investigaci&oacute;n en Materia de Salud; y Clientes y/o Proveedores: Para el cumplimiento de obligaciones contractuales y comerciales entre el responsable y sus clientes y/o proveedores.</p>
        <p>&nbsp;</p>
        <p>Adicional a lo anterior, le informamos que sus datos personales pueden ser compartidos fuera del pa&iacute;s con Proveedores dedicados al resguardo y/o almacenamiento de la informaci&oacute;n, lo anterior para garantizar la seguridad y protecci&oacute;n de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>El titular reconoce y acepta que Promedic&reg; no requiere autorizaci&oacute;n ni confirmaci&oacute;n del Titular para realizar Transferencia de Datos Personales nacionales o internacionales en los casos previstos en el Art&iacute;culo 37 de la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares.</p>
        <p>&nbsp;</p>
        <p>En todo momento Promedic&reg; realizara las acciones que considere pertinentes con la finalidad de garantizar la protecci&oacute;n y resguardo de sus datos personales.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</strong></p>
        <p>Usted tiene derecho a conocer qu&eacute; datos personales tenemos de usted, para qu&eacute; los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la correcci&oacute;n de su informaci&oacute;n personal en caso de que est&eacute; desactualizada, sea inexacta o incompleta (Rectificaci&oacute;n); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no est&aacute; siendo utilizada adecuadamente (Cancelaci&oacute;n); as&iacute; como oponerse al uso de sus datos personales para fines espec&iacute;ficos (Oposici&oacute;n). Estos derechos se conocen como derechos ARCO.</p>
        <p>&nbsp;</p>
        <p>Para el ejercicio de cualquiera de los derechos ARCO, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntarse la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copia de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Tipo de Derecho ARCO que se pretende ejercer. (Acceso, Rectificaci&oacute;n, Cancelaci&oacute;n u Oposici&oacute;n); IV) Una descripci&oacute;n clara y precisa de lo que pretende ejercer sobre sus Datos Personales; V) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales; VI) En caso de solicitar una rectificaci&oacute;n de datos personales, deber&aacute; de indicar tambi&eacute;n, las modificaciones a realizarse y aportar la documentaci&oacute;n que sustente su petici&oacute;n (donde los datos est&eacute;n correctos) y; VII) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con al Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>Usted puede revocar su consentimiento para el uso de sus datos personales</strong></p>
        <p>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales. Asimismo, usted deber&aacute; considerar que para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando el servicio que nos solicit&oacute;, o la conclusi&oacute;n de su relaci&oacute;n con nosotros.</p>
        <p>&nbsp;</p>
        <p>Para revocar su consentimiento, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntar la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copias de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Una descripci&oacute;n clara y precisa de los datos personales respecto de los cuales busca revocar su consentimiento; IV) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales y; V) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Su solicitud ser&aacute; contestada mediante correo electr&oacute;nico por parte del Departamento de Datos Personales en un plazo m&aacute;ximo de 20 (veinte) d&iacute;as h&aacute;biles contados desde el d&iacute;a en que se haya recibido. En caso de que la solicitud se conteste de manera positiva o procedente, la petici&oacute;n se podr&aacute; hacer efectiva en un plazo m&aacute;ximo de 15 (quince) d&iacute;as h&aacute;biles. Los plazos se&ntilde;alados en este p&aacute;rrafo podr&aacute;n prorrogarse por el responsable en una sola ocasi&oacute;n y por un periodo igual en caso de ser necesario.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con el Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede limitar el uso o divulgaci&oacute;n de su informaci&oacute;n personal?</strong></p>
        <p>Para limitar el uso y/o divulgaci&oacute;n de sus Datos Personales, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p><strong>El uso de tecnolog&iacute;as de rastreo en nuestro portal de internet</strong></p>
        <p>Le informamos que en nuestra p&aacute;gina de internet utilizamos cookies, web beacons u otras tecnolog&iacute;as, a trav&eacute;s de las cuales es posible monitorear su comportamiento como usuario de internet, as&iacute; como brindarle un mejor servicio y experiencia al navegar en nuestra p&aacute;gina.</p>
        <p>&nbsp;</p>
        <p>Los datos que se obtienen con el uso de estas tecnolog&iacute;as ser&aacute;n tratados &uacute;nicamente para personalizar y mejorar el contenido de nuestro sitio de internet, evaluar el uso del mismo, y simplificar su experiencia cuando vuelva a visitarlo.</p>
        <p>&nbsp;</p>
        <p>Es posible deshabilitar estas tecnolog&iacute;as de seguimiento en todo tipo de buscadores; sin embargo, para ejemplificar, se se&ntilde;alan a continuaci&oacute;n los pasos que hay que seguir para deshabilitarlas en Google Chrome: Ir al men&uacute; &ldquo;Herramientas&rdquo;; dar clic en &ldquo;borrar datos de navegaci&oacute;n&rdquo;, seleccionar la opci&oacute;n &ldquo;eliminar cookies y otros datos de sitios y de complementos&rdquo;, dar clic en &ldquo;aceptar&rdquo;. Al deshabilitar o bloquear estos mecanismos se puede ocasionar que el sitio no funcione correctamente.&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>Enlaces de otros sitios de Internet.</strong></p>
        <p>Nuestro sitio de internet puede contener, para su conveniencia, enlaces a otros sitios en Internet que no pertenecen a Promedic&reg;. Promedic&reg; no ha revisado los Avisos de Privacidad de dichos sitios, por lo que no garantiza ni se hace responsable del contenido de dichos, ni del tratamiento que tales sitios den a sus datos personales. Por tanto, le sugerimos que lea cuidadosamente los Avisos de Privacidad de cada uno de los sitios que visita en Internet.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede conocer los cambios en este aviso de privacidad?</strong></p>
        <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras pr&aacute;cticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas</p>
        <p>&nbsp;</p>
        <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a trav&eacute;s del sitio: <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p><strong>Su consentimiento para el tratamiento de sus datos personales</strong></p>
        <p>Le&iacute;do el presente documento y entendiendo el alcance del mismo otorg&oacute; mi consentimiento para que Promedic&reg; de tratamiento a mis datos personales de conformidad con lo establecido en el presente Aviso de Privacidad.</p>
        <p>&nbsp;</p>
        <p>Acepto y reconozco que cualquier modificaci&oacute;n al presente Aviso de Privacidad se me dar&aacute; aviso a trav&eacute;s del sitio web <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p align="right">&nbsp;</p>
        <p align="right">&Uacute;ltima actualizaci&oacute;n: 13 de junio de 2022.</p>
        <p align="right">&nbsp;</p>
        <p align="center" id="contacto"><strong>AVISO DE PRIVACIDAD (Contacto)</strong></p>
        <p align="center"><strong>&nbsp;</strong></p>
        <p>M&eacute;dica Profesional Integral, S.A. de C.V. empresa conocida comercialmente como Promedic&reg;, con domicilio en calle Jimenez 465, Piso 9, Colonia Centro, San Pedro Garza Garc&iacute;a, Nuevo Le&oacute;n, C&oacute;digo Postal 66230, y portal de internet <a href="https://promedic.com.mx/">https://promedic.com.mx/</a> es el responsable del uso, tratamiento y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Para qu&eacute; fines utilizaremos sus datos personales? </strong></p>
        <p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son:</p>
        <p>I) Dar respuesta a sus dudas o comentarios y/o proporcionarle la informaci&oacute;n que solicite;</p>
        <p>II) Registro hist&oacute;rico y estad&iacute;stica de consultas;</p>
        <p>III) Encuestas y evaluaciones de calidad sobre la prestaci&oacute;n de nuestros servicios;</p>
        <p>IV) Realizar estudios de mercado y h&aacute;bitos de consumo; y</p>
        <p>V) Enviar publicidad acerca de nuestros diferentes productos o servicios y/o promociones a trav&eacute;s de medios impresos y/o digitales y/o hacer llamadas telef&oacute;nicas con fines publicitarios, mercadotecnia o publicitarios.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Qu&eacute; datos personales utilizaremos para estos fines?</strong></p>
        <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>
        <p>I) Datos de identificaci&oacute;n: pudiendo ser nombre, fecha de nacimiento y/o edad;</p>
        <p>II) Datos de contacto: pudiendo ser correo electr&oacute;nico, tel&eacute;fono fijo y/o trabajo y/o m&oacute;vil;</p>
        <p>&nbsp;</p>
        <p>La descripci&oacute;n de los datos personales que ser&aacute;n utilizados y se encuentran enumerados en los p&aacute;rrafos anteriores, son de car&aacute;cter enunciativo mas no limitativo, pudiendo limitarse o ampliarse de acuerdo al servicio contratado.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Con qui&eacute;n compartimos su informaci&oacute;n personal y para qu&eacute; fines?</strong></p>
        <p>Le informamos que sus datos personales son compartidos dentro del pa&iacute;s con las siguientes personas, empresas, organizaciones o autoridades distintas a nosotros, para los siguientes fines: Sociedades Controladoras, filiales y/o subsidiarias:&nbsp; con la finalidad de resguardo Centralizado de la Informaci&oacute;n, control de altas, bajas y/o modificaciones, Informe de actividades y Registro de Consultas; Sociedad M&eacute;dicas Certificadas dedicadas a la Investigaci&oacute;n, Autoridades, Organismos o entidades gubernamentales: Para el cumplimiento a las obligaciones se&ntilde;aladas en la legislaci&oacute;n aplicable en Materia de Salud y/o cumplimiento a requerimientos de la misma autoridad y/o investigaci&oacute;n en Materia de Salud; Clientes y/o Proveedores: Para el cumplimiento de obligaciones contractuales y comerciales entre el responsable y sus clientes y/o proveedores.</p>
        <p>&nbsp;</p>
        <p>Adicional a lo anterior, le informamos que sus datos personales pueden ser compartidos fuera del pa&iacute;s con Proveedores dedicados al resguardo y/o almacenamiento de la informaci&oacute;n, lo anterior para garantizar la seguridad y protecci&oacute;n de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>El titular reconoce y acepta que Promedic&reg; no requiere autorizaci&oacute;n ni confirmaci&oacute;n del Titular para realizar Transferencia de Datos Personales nacionales o internacionales en los casos previstos en el Art&iacute;culo 37 de la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares.</p>
        <p>&nbsp;</p>
        <p>En todo momento Promedic&reg; realizara las acciones que considere pertinentes con la finalidad de garantizar la protecci&oacute;n y resguardo de sus datos personales.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</strong></p>
        <p>Usted tiene derecho a conocer qu&eacute; datos personales tenemos de usted, para qu&eacute; los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la correcci&oacute;n de su informaci&oacute;n personal en caso de que est&eacute; desactualizada, sea inexacta o incompleta (Rectificaci&oacute;n); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no est&aacute; siendo utilizada adecuadamente (Cancelaci&oacute;n); as&iacute; como oponerse al uso de sus datos personales para fines espec&iacute;ficos (Oposici&oacute;n). Estos derechos se conocen como derechos ARCO.</p>
        <p>&nbsp;</p>
        <p>Para el ejercicio de cualquiera de los derechos ARCO, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntarse la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copia de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Tipo de Derecho ARCO que se pretende ejercer. (Acceso, Rectificaci&oacute;n, Cancelaci&oacute;n u Oposici&oacute;n); IV) Una descripci&oacute;n clara y precisa de lo que pretende ejercer sobre sus Datos Personales; V) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales; VI) En caso de solicitar una rectificaci&oacute;n de datos personales, deber&aacute; de indicar tambi&eacute;n, las modificaciones a realizarse y aportar la documentaci&oacute;n que sustente su petici&oacute;n (donde los datos est&eacute;n correctos) y; VII) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con al Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>Usted puede revocar su consentimiento para el uso de sus datos personales</strong></p>
        <p>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales. Asimismo, usted deber&aacute; considerar que para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando el servicio que nos solicit&oacute;, o la conclusi&oacute;n de su relaci&oacute;n con nosotros.</p>
        <p>&nbsp;</p>
        <p>Para revocar su consentimiento, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntar la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copias de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Una descripci&oacute;n clara y precisa de los datos personales respecto de los cuales busca revocar su consentimiento; IV) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales y; V) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Su solicitud ser&aacute; contestada mediante correo electr&oacute;nico por parte del Departamento de Datos Personales en un plazo m&aacute;ximo de 20 (veinte) d&iacute;as h&aacute;biles contados desde el d&iacute;a en que se haya recibido. En caso de que la solicitud se conteste de manera positiva o procedente, la petici&oacute;n se podr&aacute; hacer efectiva en un plazo m&aacute;ximo de 15 (quince) d&iacute;as h&aacute;biles. Los plazos se&ntilde;alados en este p&aacute;rrafo podr&aacute;n prorrogarse por el responsable en una sola ocasi&oacute;n y por un periodo igual en caso de ser necesario.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con el Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede limitar el uso o divulgaci&oacute;n de su informaci&oacute;n personal?</strong></p>
        <p>Para limitar el uso y/o divulgaci&oacute;n de sus Datos Personales, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p><strong>El uso de tecnolog&iacute;as de rastreo en nuestro portal de internet</strong></p>
        <p>Le informamos que en nuestra p&aacute;gina de internet utilizamos cookies, web beacons u otras tecnolog&iacute;as, a trav&eacute;s de las cuales es posible monitorear su comportamiento como usuario de internet, as&iacute; como brindarle un mejor servicio y experiencia al navegar en nuestra p&aacute;gina.</p>
        <p>&nbsp;</p>
        <p>Los datos que se obtienen con el uso de estas tecnolog&iacute;as ser&aacute;n tratados &uacute;nicamente para personalizar y mejorar el contenido de nuestro sitio de internet, evaluar el uso del mismo, y simplificar su experiencia cuando vuelva a visitarlo.</p>
        <p>&nbsp;</p>
        <p>Es posible deshabilitar estas tecnolog&iacute;as de seguimiento en todo tipo de buscadores; sin embargo, para ejemplificar, se se&ntilde;alan a continuaci&oacute;n los pasos que hay que seguir para deshabilitarlas en Google Chrome: Ir al men&uacute; &ldquo;Herramientas&rdquo;; dar clic en &ldquo;borrar datos de navegaci&oacute;n&rdquo;, seleccionar la opci&oacute;n &ldquo;eliminar cookies y otros datos de sitios y de complementos&rdquo;, dar clic en &ldquo;aceptar&rdquo;. Al deshabilitar o bloquear estos mecanismos se puede ocasionar que el sitio no funcione correctamente.&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>Enlaces de otros sitios de Internet.</strong></p>
        <p>Nuestro sitio de internet puede contener, para su conveniencia, enlaces a otros sitios en Internet que no pertenecen a Promedic&reg;. Promedic&reg; no ha revisado los Avisos de Privacidad de dichos sitios, por lo que no garantiza ni se hace responsable del contenido de dichos, ni del tratamiento que tales sitios den a sus datos personales. Por tanto, le sugerimos que lea cuidadosamente los Avisos de Privacidad de cada uno de los sitios que visita en Internet.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede conocer los cambios en este aviso de privacidad?</strong></p>
        <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras pr&aacute;cticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas</p>
        <p>&nbsp;</p>
        <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a trav&eacute;s del sitio: <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p><strong>Su consentimiento para el tratamiento de sus datos personales</strong></p>
        <p>Le&iacute;do el presente documento y entendiendo el alcance del mismo otorg&oacute; mi consentimiento para que Promedic&reg; de tratamiento a mis datos personales de conformidad con lo establecido en el presente Aviso de Privacidad.</p>
        <p>&nbsp;</p>
        <p>Acepto y reconozco que cualquier modificaci&oacute;n al presente Aviso de Privacidad se me dar&aacute; aviso a trav&eacute;s del sitio web <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p align="right">&nbsp;</p>
        <p align="right">&Uacute;ltima actualizaci&oacute;n: 13 de junio de 2022.</p>
        <p>&nbsp;</p>
        <p align="center" id="proveedores"><strong>AVISO DE PRIVACIDAD (Proveedores)</strong></p>
        <p align="center"><strong>&nbsp;</strong></p>
        <p>M&eacute;dica Profesional Integral, S.A. de C.V. empresa conocida comercialmente como Promedic&reg;, con domicilio en calle Jimenez 465, Piso 9, Colonia Centro, San Pedro Garza Garc&iacute;a, Nuevo Le&oacute;n, C&oacute;digo Postal 66230, y portal de internet <a href="https://promedic.com.mx/">https://promedic.com.mx/</a> es el responsable del uso, tratamiento y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Para qu&eacute; fines utilizaremos sus datos personales?</strong></p>
        <p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son:</p>
        <p>I) Investigaci&oacute;n, An&aacute;lisis y Registro de Proveedores,</p>
        <p>II) Pago de saldos y facturas a Proveedores;</p>
        <p>III) Dar cumplimiento a las obligaciones legales, fiscales y requerimientos de la autoridad competente;</p>
        <p>IV) Contrataci&oacute;n de Servicios;</p>
        <p>V) Seguridad en nuestras instalaciones.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Qu&eacute; datos personales utilizaremos para estos fines?</strong></p>
        <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>
        <p>I) Datos de identificaci&oacute;n del proveedor y/o apoderado y/o represente legal: pudiendo ser nombre, clave &uacute;nica de registro de poblaci&oacute;n (CURP), registro federal de contribuyentes (RFC), domicilio, y/o registro patronal;</p>
        <p>II) Datos de Contacto del proveedor y/o apoderado y/o represente legal; pudiendo ser direcci&oacute;n, correo electr&oacute;nico, tel&eacute;fono fijo y/o trabajo y/o m&oacute;vil;</p>
        <p>III) Datos Comerciales del proveedor: pudiendo ser informaci&oacute;n de principales clientes y proveedores, marcas y/o nombres comerciales;</p>
        <p>IV) Datos de Obligaciones Fiscales del proveedor: pudiendo ser presentaciones de declaraciones mensuales, anuales, opini&oacute;n de cumplimiento de obligaciones fiscales y/o constancia de situaci&oacute;n fiscal;</p>
        <p>V) Datos de Obligaciones Laborales del proveedor: pudiendo ser aportaciones de Seguridad Social e Infonavit y/o sindicato;</p>
        <p>VI) Datos de Fianzas y Seguros del proveedor; pudiendo ser p&oacute;lizas de fianzas y/o seguros; y</p>
        <p>VII) Datos de Imagen y Voz del proveedor y/o apoderado y/o represente legal: pudiendo ser a trav&eacute;s de videovigilancia en tiempo real.</p>
        <p>&nbsp;</p>
        <p>Adem&aacute;s de los datos personales mencionados anteriormente, para las finalidades informadas en el presente aviso de privacidad utilizaremos los siguientes datos personales considerados como sensibles, que requieren de especial protecci&oacute;n:</p>
        <p>I) Informaci&oacute;n financiera y Patrimonial del proveedor;</p>
        <p>II) Opiniones pol&iacute;ticas y/o afiliaci&oacute;n sindical del proveedor;</p>
        <p>III) Datos Migratorios del proveedor y/o apoderado y/o represente legal: pudiendo ser calidad migratoria dentro de los Estados Unidos Mexicanos.</p>
        <p>&nbsp;</p>
        <p>La descripci&oacute;n de los datos personales que ser&aacute;n utilizados y se encuentran enumerados en los p&aacute;rrafos anteriores, son de car&aacute;cter enunciativo mas no limitativo, pudiendo limitarse o ampliarse de acuerdo al servicio contratado por Promedic&reg;.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Con qui&eacute;n compartimos su informaci&oacute;n personal y para qu&eacute; fines?</strong></p>
        <p>Le informamos que sus datos personales son compartidos dentro del pa&iacute;s con las siguientes personas, empresas, organizaciones o autoridades distintas a nosotros, para los siguientes fines: Sociedades Controladoras, filiales y/o subsidiarias:&nbsp; con la finalidad de resguardo Centralizado de la Informaci&oacute;n, control de altas, bajas y/o modificaciones, Informe de actividades y Registro de Proveedores; Autoridades, Organismos o entidades gubernamentales: Para el cumplimiento a las obligaciones se&ntilde;aladas en la legislaci&oacute;n aplicable en Materia de Salud y/o cumplimiento a requerimientos de la misma autoridad y/o investigaci&oacute;n en Materia de Salud; y Clientes y/o Proveedores: Para el cumplimiento de obligaciones contractuales y comerciales entre el responsable y sus clientes y/o proveedores.</p>
        <p>&nbsp;</p>
        <p>Adicional a lo anterior, le informamos que sus datos personales pueden ser compartidos fuera del pa&iacute;s con Proveedores dedicados al resguardo y/o almacenamiento de la informaci&oacute;n, lo anterior para garantizar la seguridad y protecci&oacute;n de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>El titular reconoce y acepta que Promedic&reg; no requiere autorizaci&oacute;n ni confirmaci&oacute;n del Titular para realizar Transferencia de Datos Personales nacionales o internacionales en los casos previstos en el Art&iacute;culo 37 de la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares.</p>
        <p>&nbsp;</p>
        <p>En todo momento Promedic&reg; realizara las acciones que considere pertinentes con la finalidad de garantizar la protecci&oacute;n y resguardo de sus datos personales.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</strong></p>
        <p>Usted tiene derecho a conocer qu&eacute; datos personales tenemos de usted, para qu&eacute; los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la correcci&oacute;n de su informaci&oacute;n personal en caso de que est&eacute; desactualizada, sea inexacta o incompleta (Rectificaci&oacute;n); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no est&aacute; siendo utilizada adecuadamente (Cancelaci&oacute;n); as&iacute; como oponerse al uso de sus datos personales para fines espec&iacute;ficos (Oposici&oacute;n). Estos derechos se conocen como derechos ARCO.</p>
        <p>&nbsp;</p>
        <p>Para el ejercicio de cualquiera de los derechos ARCO, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntarse la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copia de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Tipo de Derecho ARCO que se pretende ejercer. (Acceso, Rectificaci&oacute;n, Cancelaci&oacute;n u Oposici&oacute;n); IV) Una descripci&oacute;n clara y precisa de lo que pretende ejercer sobre sus Datos Personales; V) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales; VI) En caso de solicitar una rectificaci&oacute;n de datos personales, deber&aacute; de indicar tambi&eacute;n, las modificaciones a realizarse y aportar la documentaci&oacute;n que sustente su petici&oacute;n (donde los datos est&eacute;n correctos) y; VII) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con al Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>Usted puede revocar su consentimiento para el uso de sus datos personales</strong></p>
        <p>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales. Asimismo, usted deber&aacute; considerar que para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando el servicio que nos solicit&oacute;, o la conclusi&oacute;n de su relaci&oacute;n con nosotros.</p>
        <p>&nbsp;</p>
        <p>Para revocar su consentimiento, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntar la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copias de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Una descripci&oacute;n clara y precisa de los datos personales respecto de los cuales busca revocar su consentimiento; IV) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales y; V) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Su solicitud ser&aacute; contestada mediante correo electr&oacute;nico por parte del Departamento de Datos Personales en un plazo m&aacute;ximo de 20 (veinte) d&iacute;as h&aacute;biles contados desde el d&iacute;a en que se haya recibido. En caso de que la solicitud se conteste de manera positiva o procedente, la petici&oacute;n se podr&aacute; hacer efectiva en un plazo m&aacute;ximo de 15 (quince) d&iacute;as h&aacute;biles. Los plazos se&ntilde;alados en este p&aacute;rrafo podr&aacute;n prorrogarse por el responsable en una sola ocasi&oacute;n y por un periodo igual en caso de ser necesario.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con el Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede limitar el uso o divulgaci&oacute;n de su informaci&oacute;n personal?</strong></p>
        <p>Para limitar el uso y/o divulgaci&oacute;n de sus Datos Personales, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p><strong>El uso de tecnolog&iacute;as de rastreo en nuestro portal de internet</strong></p>
        <p>Le informamos que en nuestra p&aacute;gina de internet utilizamos cookies, web beacons u otras tecnolog&iacute;as, a trav&eacute;s de las cuales es posible monitorear su comportamiento como usuario de internet, as&iacute; como brindarle un mejor servicio y experiencia al navegar en nuestra p&aacute;gina.</p>
        <p>&nbsp;</p>
        <p>Los datos que se obtienen con el uso de estas tecnolog&iacute;as ser&aacute;n tratados &uacute;nicamente para personalizar y mejorar el contenido de nuestro sitio de internet, evaluar el uso del mismo, y simplificar su experiencia cuando vuelva a visitarlo.</p>
        <p>&nbsp;</p>
        <p>Es posible deshabilitar estas tecnolog&iacute;as de seguimiento en todo tipo de buscadores; sin embargo, para ejemplificar, se se&ntilde;alan a continuaci&oacute;n los pasos que hay que seguir para deshabilitarlas en Google Chrome: Ir al men&uacute; &ldquo;Herramientas&rdquo;; dar clic en &ldquo;borrar datos de navegaci&oacute;n&rdquo;, seleccionar la opci&oacute;n &ldquo;eliminar cookies y otros datos de sitios y de complementos&rdquo;, dar clic en &ldquo;aceptar&rdquo;. Al deshabilitar o bloquear estos mecanismos se puede ocasionar que el sitio no funcione correctamente.&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>Enlaces de otros sitios de Internet.</strong></p>
        <p>Nuestro sitio de internet puede contener, para su conveniencia, enlaces a otros sitios en Internet que no pertenecen a Promedic&reg;. Promedic&reg; no ha revisado los Avisos de Privacidad de dichos sitios, por lo que no garantiza ni se hace responsable del contenido de dichos, ni del tratamiento que tales sitios den a sus datos personales. Por tanto, le sugerimos que lea cuidadosamente los Avisos de Privacidad de cada uno de los sitios que visita en Internet.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede conocer los cambios en este aviso de privacidad?</strong></p>
        <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras pr&aacute;cticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas</p>
        <p>&nbsp;</p>
        <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a trav&eacute;s del sitio: <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p><strong>Su consentimiento para el tratamiento de sus datos personales</strong></p>
        <p>Le&iacute;do el presente documento y entendiendo el alcance del mismo otorg&oacute; mi consentimiento para que Promedic&reg; de tratamiento a mis datos personales de conformidad con lo establecido en el presente Aviso de Privacidad.</p>
        <p>&nbsp;</p>
        <p>Acepto y reconozco que cualquier modificaci&oacute;n al presente Aviso de Privacidad se me dar&aacute; aviso a trav&eacute;s del sitio web <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p align="right">&nbsp;</p>
        <p align="right">&Uacute;ltima actualizaci&oacute;n: 13 de junio de 2022.</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p align="center"><strong>AVISO DE PRIVACIDAD (Proveedores)</strong></p>
        <p align="center"><strong>&nbsp;</strong></p>
        <p>M&eacute;dica Profesional Integral, S.A. de C.V. empresa conocida comercialmente como Promedic&reg;, con domicilio en calle Jimenez 465, Piso 9, Colonia Centro, San Pedro Garza Garc&iacute;a, Nuevo Le&oacute;n, C&oacute;digo Postal 66230, es el responsable del uso, tratamiento y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Para qu&eacute; fines utilizaremos sus datos personales? </strong>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son: I) Investigaci&oacute;n, An&aacute;lisis y Registro de Proveedores, II) Pago de saldos y facturas a Proveedores; III) Dar cumplimiento a las obligaciones legales, fiscales y requerimientos de la autoridad competente; IV) Contrataci&oacute;n de Servicios; V) Seguridad en nuestras instalaciones.</p>
        <p>&nbsp;</p>
        <p>El titular reconoce y acepta que Promedic&reg; no requiere autorizaci&oacute;n ni confirmaci&oacute;n del Titular para realizar Transferencia de Datos Personales nacionales o internacionales en los casos previstos en el Art&iacute;culo 37 de la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares. En todo momento Promedic&reg; realizara las acciones que considere pertinentes con la finalidad de garantizar la protecci&oacute;n y resguardo de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>Le&iacute;do el presente documento y entendiendo el alcance del mismo, otorg&oacute; mi consentimiento para que Promedic&reg;, de tratamiento a mis datos personales de conformidad con lo establecido en el Aviso de Privacidad Integral que se puso a mi disposici&oacute;n de manera impresa y digital, aceptando y reconociendo que cualquier modificaci&oacute;n al Aviso de Privacidad Integral se me dar&aacute; aviso a trav&eacute;s del sitio web <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p align="right">&nbsp;</p>
        <p align="right">&Uacute;ltima actualizaci&oacute;n: 13 de junio de 2022.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p align="center" id="visitantes-y-recepcion"><strong>AVISO DE PRIVACIDAD (Visitantes y Recepci&oacute;n)</strong></p>
        <p align="center"><strong>&nbsp;</strong></p>
        <p>M&eacute;dica Profesional Integral, S.A. de C.V. empresa conocida comercialmente como Promedic&reg;, con domicilio en calle Jimenez 465, Piso 9, Colonia Centro, San Pedro Garza Garc&iacute;a, Nuevo Le&oacute;n, C&oacute;digo Postal 66230, y portal de internet <a href="https://promedic.com.mx/">https://promedic.com.mx/</a> es el responsable del uso, tratamiento y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Para qu&eacute; fines utilizaremos sus datos personales?</strong></p>
        <p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son:</p>
        <p>I) Registro de entradas y salidas de visitantes;</p>
        <p>II) Registro de visitas a cada departamento interno;</p>
        <p>III) Canalizar la visita con el personal correspondiente;</p>
        <p>IV) Proteger la Propiedad, derechos y personal del responsable; y</p>
        <p>V) Brindar seguridad en nuestras instalaciones.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Qu&eacute; datos personales utilizaremos para estos fines?</strong></p>
        <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>
        <p>I) Documentos de Identidad: pudiendo ser identificaci&oacute;n con fotograf&iacute;a;</p>
        <p>II) Datos de identificaci&oacute;n: pudiendo ser nombre, direcci&oacute;n, fecha de nacimiento y/o edad;</p>
        <p>III) Datos laborales: pudiendo ser patr&oacute;n o empleador y/o n&uacute;mero de empleado;</p>
        <p>IV) Datos biom&eacute;tricos: pudiendo ser huellas dactilares; y</p>
        <p>&nbsp;</p>
        <p>La descripci&oacute;n de los datos personales que ser&aacute;n utilizados y se encuentran enumerados en los p&aacute;rrafos anteriores, son de car&aacute;cter enunciativo mas no limitativo, pudiendo limitarse o ampliarse de acuerdo al &aacute;rea o departamento al que se pretende ingresar.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Con qui&eacute;n compartimos su informaci&oacute;n personal y para qu&eacute; fines?</strong></p>
        <p>Le informamos que sus datos personales son compartidos dentro del pa&iacute;s con las siguientes personas, empresas, organizaciones o autoridades distintas a nosotros, para los siguientes fines: Sociedades Controladoras, filiales y/o subsidiarias:&nbsp; con la finalidad de resguardo Centralizado de la Informaci&oacute;n, control de altas, bajas y/o modificaciones, Informe de actividades y Registro de Clientes; Autoridades, Organismos o entidades gubernamentales: Para el cumplimiento a las obligaciones se&ntilde;aladas en la legislaci&oacute;n aplicable en Materia de Salud y/o cumplimiento a requerimientos de la misma autoridad y; Clientes y/o Proveedores: Para el cumplimiento de obligaciones contractuales y comerciales entre el responsable y sus clientes y/o proveedores.</p>
        <p>&nbsp;</p>
        <p>Adicional a lo anterior, le informamos que sus datos personales pueden ser compartidos fuera del pa&iacute;s con Proveedores dedicados al resguardo y/o almacenamiento de la informaci&oacute;n, lo anterior para garantizar la seguridad y protecci&oacute;n de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>El titular reconoce y acepta que Promedic&reg; no requiere autorizaci&oacute;n ni confirmaci&oacute;n del Titular para realizar Transferencia de Datos Personales nacionales o internacionales en los casos previstos en el Art&iacute;culo 37 de la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares.</p>
        <p>&nbsp;</p>
        <p>En todo momento Promedic&reg; realizara las acciones que considere pertinentes con la finalidad de garantizar la protecci&oacute;n y resguardo de sus datos personales.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</strong></p>
        <p>Usted tiene derecho a conocer qu&eacute; datos personales tenemos de usted, para qu&eacute; los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la correcci&oacute;n de su informaci&oacute;n personal en caso de que est&eacute; desactualizada, sea inexacta o incompleta (Rectificaci&oacute;n); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no est&aacute; siendo utilizada adecuadamente (Cancelaci&oacute;n); as&iacute; como oponerse al uso de sus datos personales para fines espec&iacute;ficos (Oposici&oacute;n). Estos derechos se conocen como derechos ARCO.</p>
        <p>&nbsp;</p>
        <p>Para el ejercicio de cualquiera de los derechos ARCO, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntarse la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copia de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Tipo de Derecho ARCO que se pretende ejercer. (Acceso, Rectificaci&oacute;n, Cancelaci&oacute;n u Oposici&oacute;n); IV) Una descripci&oacute;n clara y precisa de lo que pretende ejercer sobre sus Datos Personales; V) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales; VI) En caso de solicitar una rectificaci&oacute;n de datos personales, deber&aacute; de indicar tambi&eacute;n, las modificaciones a realizarse y aportar la documentaci&oacute;n que sustente su petici&oacute;n (donde los datos est&eacute;n correctos) y; VII) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con al Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>Usted puede revocar su consentimiento para el uso de sus datos personales</strong></p>
        <p>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales. Asimismo, usted deber&aacute; considerar que para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando el servicio que nos solicit&oacute;, o la conclusi&oacute;n de su relaci&oacute;n con nosotros.</p>
        <p>&nbsp;</p>
        <p>Para revocar su consentimiento, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntar la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copias de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Una descripci&oacute;n clara y precisa de los datos personales respecto de los cuales busca revocar su consentimiento; IV) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales y; V) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Su solicitud ser&aacute; contestada mediante correo electr&oacute;nico por parte del Departamento de Datos Personales en un plazo m&aacute;ximo de 20 (veinte) d&iacute;as h&aacute;biles contados desde el d&iacute;a en que se haya recibido. En caso de que la solicitud se conteste de manera positiva o procedente, la petici&oacute;n se podr&aacute; hacer efectiva en un plazo m&aacute;ximo de 15 (quince) d&iacute;as h&aacute;biles. Los plazos se&ntilde;alados en este p&aacute;rrafo podr&aacute;n prorrogarse por el responsable en una sola ocasi&oacute;n y por un periodo igual en caso de ser necesario.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con el Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede limitar el uso o divulgaci&oacute;n de su informaci&oacute;n personal?</strong></p>
        <p>Para limitar el uso y/o divulgaci&oacute;n de sus Datos Personales, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p><strong>El uso de tecnolog&iacute;as de rastreo en nuestro portal de internet</strong></p>
        <p>Le informamos que en nuestra p&aacute;gina de internet utilizamos cookies, web beacons u otras tecnolog&iacute;as, a trav&eacute;s de las cuales es posible monitorear su comportamiento como usuario de internet, as&iacute; como brindarle un mejor servicio y experiencia al navegar en nuestra p&aacute;gina.</p>
        <p>&nbsp;</p>
        <p>Los datos que se obtienen con el uso de estas tecnolog&iacute;as ser&aacute;n tratados &uacute;nicamente para personalizar y mejorar el contenido de nuestro sitio de internet, evaluar el uso del mismo, y simplificar su experiencia cuando vuelva a visitarlo.</p>
        <p>&nbsp;</p>
        <p>Es posible deshabilitar estas tecnolog&iacute;as de seguimiento en todo tipo de buscadores; sin embargo, para ejemplificar, se se&ntilde;alan a continuaci&oacute;n los pasos que hay que seguir para deshabilitarlas en Google Chrome: Ir al men&uacute; &ldquo;Herramientas&rdquo;; dar clic en &ldquo;borrar datos de navegaci&oacute;n&rdquo;, seleccionar la opci&oacute;n &ldquo;eliminar cookies y otros datos de sitios y de complementos&rdquo;, dar clic en &ldquo;aceptar&rdquo;. Al deshabilitar o bloquear estos mecanismos se puede ocasionar que el sitio no funcione correctamente.&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>Enlaces de otros sitios de Internet.</strong></p>
        <p>Nuestro sitio de internet puede contener, para su conveniencia, enlaces a otros sitios en Internet que no pertenecen a Promedic&reg;. Promedic&reg; no ha revisado los Avisos de Privacidad de dichos sitios, por lo que no garantiza ni se hace responsable del contenido de dichos, ni del tratamiento que tales sitios den a sus datos personales. Por tanto, le sugerimos que lea cuidadosamente los Avisos de Privacidad de cada uno de los sitios que visita en Internet.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede conocer los cambios en este aviso de privacidad?</strong></p>
        <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras pr&aacute;cticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas</p>
        <p>&nbsp;</p>
        <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a trav&eacute;s del sitio: <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p><strong>Su consentimiento para el tratamiento de sus datos personales</strong></p>
        <p>Le&iacute;do el presente documento y entendiendo el alcance del mismo otorg&oacute; mi consentimiento para que Promedic&reg; de tratamiento a mis datos personales de conformidad con lo establecido en el presente Aviso de Privacidad.</p>
        <p>&nbsp;</p>
        <p>Acepto y reconozco que cualquier modificaci&oacute;n al presente Aviso de Privacidad se me dar&aacute; aviso a trav&eacute;s del sitio web <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p align="right">&nbsp;</p>
        <p align="right">&Uacute;ltima actualizaci&oacute;n: 13 de junio de 2022.</p>
        <p>&nbsp;</p>
        <p align="center" id="seguridad-y-vigilancia"><strong>AVISO DE PRIVACIDAD (Seguridad y Vigilancia)</strong></p>
        <p align="center"><strong>&nbsp;</strong></p>
        <p>M&eacute;dica Profesional Integral, S.A. de C.V. empresa conocida comercialmente como Promedic&reg;, con domicilio en calle Jimenez 465, Piso 9, Colonia Centro, San Pedro Garza Garc&iacute;a, Nuevo Le&oacute;n, C&oacute;digo Postal 66230, y portal de internet <a href="https://promedic.com.mx/">https://promedic.com.mx/</a> es el responsable del uso, tratamiento y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Para qu&eacute; fines utilizaremos sus datos personales?</strong></p>
        <p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son:</p>
        <p>I) Brindar seguridad y vigilancia a nuestro personal, clientes, proveedores, visitantes y cualquier persona que se encuentre en nuestras instalaciones;</p>
        <p>II) Brindar confianza a toda persona que se encuentre en nuestras instalaciones;</p>
        <p>III) Monitoreo de las actividades del personal de nuestro personal;</p>
        <p>IV) Prevenci&oacute;n de delitos;</p>
        <p>V) Investigaci&oacute;n sobre hechos delictivos y;</p>
        <p>VI) Proteger la propiedad, derechos y personal del responsable.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Qu&eacute; datos personales utilizaremos para estos fines?</strong></p>
        <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>
        <p>I) Datos de Imagen; y</p>
        <p>II) Datos de Voz.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Con qui&eacute;n compartimos su informaci&oacute;n personal y para qu&eacute; fines?</strong></p>
        <p>Le informamos que sus datos personales son compartidos dentro del pa&iacute;s con las siguientes personas, empresas, organizaciones o autoridades distintas a nosotros, para los siguientes fines: Sociedades Controladoras, filiales y/o subsidiarias:&nbsp; con la finalidad de resguardo Centralizado de la Informaci&oacute;n, control de altas, bajas y/o modificaciones, Informe de actividades y Registro de Clientes; Autoridades, Organismos o entidades gubernamentales: Para el cumplimiento a las obligaciones se&ntilde;aladas en la legislaci&oacute;n aplicable en Materia de Salud y/o cumplimiento a requerimientos de la misma autoridad y; Clientes y/o Proveedores: Para el cumplimiento de obligaciones contractuales y comerciales entre el responsable y sus clientes y/o proveedores.</p>
        <p>&nbsp;</p>
        <p>Adicional a lo anterior, le informamos que sus datos personales pueden ser compartidos fuera del pa&iacute;s con Proveedores dedicados al resguardo y/o almacenamiento de la informaci&oacute;n, lo anterior para garantizar la seguridad y protecci&oacute;n de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>El titular reconoce y acepta que Promedic&reg; no requiere autorizaci&oacute;n ni confirmaci&oacute;n del Titular para realizar Transferencia de Datos Personales nacionales o internacionales en los casos previstos en el Art&iacute;culo 37 de la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares.</p>
        <p>&nbsp;</p>
        <p>En todo momento Promedic&reg; realizara las acciones que considere pertinentes con la finalidad de garantizar la protecci&oacute;n y resguardo de sus datos personales.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</strong></p>
        <p>Usted tiene derecho a conocer qu&eacute; datos personales tenemos de usted, para qu&eacute; los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la correcci&oacute;n de su informaci&oacute;n personal en caso de que est&eacute; desactualizada, sea inexacta o incompleta (Rectificaci&oacute;n); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no est&aacute; siendo utilizada adecuadamente (Cancelaci&oacute;n); as&iacute; como oponerse al uso de sus datos personales para fines espec&iacute;ficos (Oposici&oacute;n). Estos derechos se conocen como derechos ARCO.</p>
        <p>&nbsp;</p>
        <p>Para el ejercicio de cualquiera de los derechos ARCO, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntarse la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copia de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Tipo de Derecho ARCO que se pretende ejercer. (Acceso, Rectificaci&oacute;n, Cancelaci&oacute;n u Oposici&oacute;n); IV) Una descripci&oacute;n clara y precisa de lo que pretende ejercer sobre sus Datos Personales; V) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales; VI) En caso de solicitar una rectificaci&oacute;n de datos personales, deber&aacute; de indicar tambi&eacute;n, las modificaciones a realizarse y aportar la documentaci&oacute;n que sustente su petici&oacute;n (donde los datos est&eacute;n correctos) y; VII) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con al Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>Usted puede revocar su consentimiento para el uso de sus datos personales</strong></p>
        <p>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales. Asimismo, usted deber&aacute; considerar que para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando el servicio que nos solicit&oacute;, o la conclusi&oacute;n de su relaci&oacute;n con nosotros.</p>
        <p>&nbsp;</p>
        <p>Para revocar su consentimiento, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntar la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copias de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Una descripci&oacute;n clara y precisa de los datos personales respecto de los cuales busca revocar su consentimiento; IV) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales y; V) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Su solicitud ser&aacute; contestada mediante correo electr&oacute;nico por parte del Departamento de Datos Personales en un plazo m&aacute;ximo de 20 (veinte) d&iacute;as h&aacute;biles contados desde el d&iacute;a en que se haya recibido. En caso de que la solicitud se conteste de manera positiva o procedente, la petici&oacute;n se podr&aacute; hacer efectiva en un plazo m&aacute;ximo de 15 (quince) d&iacute;as h&aacute;biles. Los plazos se&ntilde;alados en este p&aacute;rrafo podr&aacute;n prorrogarse por el responsable en una sola ocasi&oacute;n y por un periodo igual en caso de ser necesario.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con el Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede limitar el uso o divulgaci&oacute;n de su informaci&oacute;n personal?</strong></p>
        <p>Para limitar el uso y/o divulgaci&oacute;n de sus Datos Personales, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p><strong>El uso de tecnolog&iacute;as de rastreo en nuestro portal de internet</strong></p>
        <p>Le informamos que en nuestra p&aacute;gina de internet utilizamos cookies, web beacons u otras tecnolog&iacute;as, a trav&eacute;s de las cuales es posible monitorear su comportamiento como usuario de internet, as&iacute; como brindarle un mejor servicio y experiencia al navegar en nuestra p&aacute;gina.</p>
        <p>&nbsp;</p>
        <p>Los datos que se obtienen con el uso de estas tecnolog&iacute;as ser&aacute;n tratados &uacute;nicamente para personalizar y mejorar el contenido de nuestro sitio de internet, evaluar el uso del mismo, y simplificar su experiencia cuando vuelva a visitarlo.</p>
        <p>&nbsp;</p>
        <p>Es posible deshabilitar estas tecnolog&iacute;as de seguimiento en todo tipo de buscadores; sin embargo, para ejemplificar, se se&ntilde;alan a continuaci&oacute;n los pasos que hay que seguir para deshabilitarlas en Google Chrome: Ir al men&uacute; &ldquo;Herramientas&rdquo;; dar clic en &ldquo;borrar datos de navegaci&oacute;n&rdquo;, seleccionar la opci&oacute;n &ldquo;eliminar cookies y otros datos de sitios y de complementos&rdquo;, dar clic en &ldquo;aceptar&rdquo;. Al deshabilitar o bloquear estos mecanismos se puede ocasionar que el sitio no funcione correctamente.&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>Enlaces de otros sitios de Internet.</strong></p>
        <p>Nuestro sitio de internet puede contener, para su conveniencia, enlaces a otros sitios en Internet que no pertenecen a Promedic&reg;. Promedic&reg; no ha revisado los Avisos de Privacidad de dichos sitios, por lo que no garantiza ni se hace responsable del contenido de dichos, ni del tratamiento que tales sitios den a sus datos personales. Por tanto, le sugerimos que lea cuidadosamente los Avisos de Privacidad de cada uno de los sitios que visita en Internet.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede conocer los cambios en este aviso de privacidad?</strong></p>
        <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras pr&aacute;cticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas</p>
        <p>&nbsp;</p>
        <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a trav&eacute;s del sitio: <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p><strong>Su consentimiento para el tratamiento de sus datos personales</strong></p>
        <p>Le&iacute;do el presente documento y entendiendo el alcance del mismo otorg&oacute; mi consentimiento para que Promedic&reg; de tratamiento a mis datos personales de conformidad con lo establecido en el presente Aviso de Privacidad.</p>
        <p>&nbsp;</p>
        <p>Acepto y reconozco que cualquier modificaci&oacute;n al presente Aviso de Privacidad se me dar&aacute; aviso a trav&eacute;s del sitio web <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p align="right">&nbsp;</p>
        <p align="right">&Uacute;ltima actualizaci&oacute;n: 13 de junio de 2022.</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p align="center" id="bolsa-de-trabajo"><strong>AVISO DE PRIVACIDAD (Bolsa de Trabajo)</strong></p>
        <p align="center"><strong>&nbsp;</strong></p>
        <p>M&eacute;dica Profesional Integral, S.A. de C.V. empresa conocida comercialmente como Promedic&reg;, con domicilio en calle Jimenez 465, Piso 9, Colonia Centro, San Pedro Garza Garc&iacute;a, Nuevo Le&oacute;n, C&oacute;digo Postal 66230, y portal de internet <a href="https://promedic.com.mx/">https://promedic.com.mx/</a> es el responsable del uso, tratamiento y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Para qu&eacute; fines utilizaremos sus datos personales?</strong></p>
        <p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son:</p>
        <p>I) Registro de Candidatos;</p>
        <p>II) Participar en el Proceso de selecci&oacute;n de nuestro personal en las vacantes disponibles;</p>
        <p>III) Contactar referencias familiares, personales y laborales;</p>
        <p>IV) Investigar antecedentes laborales y legales;</p>
        <p>V) Validar aptitudes, estado de Salud y situaci&oacute;n econ&oacute;mica de acuerdo al perfil de nuestras vacantes y;</p>
        <p>VI) Confirmar la situaci&oacute;n Migratoria en nuestro Pa&iacute;s.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Qu&eacute; datos personales utilizaremos para estos fines?</strong></p>
        <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>
        <p>I) Datos de identificaci&oacute;n: pudiendo ser nombre, direcci&oacute;n, fecha de nacimiento y/o edad;</p>
        <p>II) Datos familiares: pudiendo ser descendientes, conyugue y/o dependientes econ&oacute;micos;</p>
        <p>III) Datos de referencias: pudiendo ser referencias personales, incluyendo nombre y tel&eacute;fono fijo o m&oacute;vil;</p>
        <p>II) Datos de contacto: pudiendo ser direcci&oacute;n, correo electr&oacute;nico, tel&eacute;fono fijo y/o trabajo y/o m&oacute;vil;</p>
        <p>VI) Datos laborales: pudiendo ser &uacute;ltimos patrones o empleadores, as&iacute; como jefes directos y/o tel&eacute;fonos de oficina y/o correo electr&oacute;nico y/o experiencia laboral;</p>
        <p>VII) Datos acad&eacute;micos y profesionales: pudiendo ser estudios de bachillerato, t&eacute;cnicos, licenciatura, maestr&iacute;a y/o doctorado;</p>
        <p>&nbsp;</p>
        <p>Adem&aacute;s de los datos personales mencionados anteriormente, para las finalidades informadas en el presente aviso de privacidad utilizaremos los siguientes datos personales considerados como sensibles, que requieren de especial protecci&oacute;n:</p>
        <p>I) Informaci&oacute;n patrimonial: pudiendo ser declaraci&oacute;n patrimonial de bienes muebles o inmuebles;</p>
        <p>II) Datos socio econ&oacute;micos: pudiendo ser ingresos econ&oacute;micos familiares;</p>
        <p>III) Datos de afiliaci&oacute;n sindical, pudiendo ser la incorporaci&oacute;n alg&uacute;n Sindicato;</p>
        <p>IV) Datos de salud: pudiendo ser estado actual de salud;</p>
        <p>VI) Datos de Situaci&oacute;n Jur&iacute;dica: pudiendo ser conciliaciones y/o demandas pasadas o presentes;</p>
        <p>VII) Datos migratorios: pudiendo ser calidad migratoria dentro de los Estados Unidos Mexicanos.</p>
        <p>&nbsp;</p>
        <p>La descripci&oacute;n de los datos personales que ser&aacute;n utilizados y se encuentran enumerados en los p&aacute;rrafos anteriores, son de car&aacute;cter enunciativo mas no limitativo, pudiendo limitarse o ampliarse de acuerdo al perfil de la vacante disponible.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Con qui&eacute;n compartimos su informaci&oacute;n personal y para qu&eacute; fines?</strong></p>
        <p>Le informamos que sus datos personales son compartidos dentro del pa&iacute;s con las siguientes personas, empresas, organizaciones o autoridades distintas a nosotros, para los siguientes fines: Sociedades Controladoras, filiales y/o subsidiarias:&nbsp; con la finalidad de resguardo Centralizado de la Informaci&oacute;n, control de altas, bajas y/o modificaciones, Informe de actividades y Registro de Clientes; Autoridades, Organismos o entidades gubernamentales: Para el cumplimiento a las obligaciones se&ntilde;aladas en la legislaci&oacute;n aplicable en Materia de Salud y/o cumplimiento a requerimientos de la misma autoridad y; Clientes y/o Proveedores: Para el cumplimiento de obligaciones contractuales y comerciales entre el responsable y sus clientes y/o proveedores.</p>
        <p>&nbsp;</p>
        <p>Adicional a lo anterior, le informamos que sus datos personales pueden ser compartidos fuera del pa&iacute;s con Proveedores dedicados al resguardo y/o almacenamiento de la informaci&oacute;n, lo anterior para garantizar la seguridad y protecci&oacute;n de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>El titular reconoce y acepta que Promedic&reg; no requiere autorizaci&oacute;n ni confirmaci&oacute;n del Titular para realizar Transferencia de Datos Personales nacionales o internacionales en los casos previstos en el Art&iacute;culo 37 de la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares.</p>
        <p>&nbsp;</p>
        <p>En todo momento Promedic&reg; realizara las acciones que considere pertinentes con la finalidad de garantizar la protecci&oacute;n y resguardo de sus datos personales.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</strong></p>
        <p>Usted tiene derecho a conocer qu&eacute; datos personales tenemos de usted, para qu&eacute; los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la correcci&oacute;n de su informaci&oacute;n personal en caso de que est&eacute; desactualizada, sea inexacta o incompleta (Rectificaci&oacute;n); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no est&aacute; siendo utilizada adecuadamente (Cancelaci&oacute;n); as&iacute; como oponerse al uso de sus datos personales para fines espec&iacute;ficos (Oposici&oacute;n). Estos derechos se conocen como derechos ARCO.</p>
        <p>&nbsp;</p>
        <p>Para el ejercicio de cualquiera de los derechos ARCO, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntarse la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copia de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Tipo de Derecho ARCO que se pretende ejercer. (Acceso, Rectificaci&oacute;n, Cancelaci&oacute;n u Oposici&oacute;n); IV) Una descripci&oacute;n clara y precisa de lo que pretende ejercer sobre sus Datos Personales; V) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales; VI) En caso de solicitar una rectificaci&oacute;n de datos personales, deber&aacute; de indicar tambi&eacute;n, las modificaciones a realizarse y aportar la documentaci&oacute;n que sustente su petici&oacute;n (donde los datos est&eacute;n correctos) y; VII) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con al Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>Usted puede revocar su consentimiento para el uso de sus datos personales</strong></p>
        <p>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales. Asimismo, usted deber&aacute; considerar que para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando el servicio que nos solicit&oacute;, o la conclusi&oacute;n de su relaci&oacute;n con nosotros.</p>
        <p>&nbsp;</p>
        <p>Para revocar su consentimiento, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntar la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copias de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Una descripci&oacute;n clara y precisa de los datos personales respecto de los cuales busca revocar su consentimiento; IV) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales y; V) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Su solicitud ser&aacute; contestada mediante correo electr&oacute;nico por parte del Departamento de Datos Personales en un plazo m&aacute;ximo de 20 (veinte) d&iacute;as h&aacute;biles contados desde el d&iacute;a en que se haya recibido. En caso de que la solicitud se conteste de manera positiva o procedente, la petici&oacute;n se podr&aacute; hacer efectiva en un plazo m&aacute;ximo de 15 (quince) d&iacute;as h&aacute;biles. Los plazos se&ntilde;alados en este p&aacute;rrafo podr&aacute;n prorrogarse por el responsable en una sola ocasi&oacute;n y por un periodo igual en caso de ser necesario.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con el Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede limitar el uso o divulgaci&oacute;n de su informaci&oacute;n personal?</strong></p>
        <p>Para limitar el uso y/o divulgaci&oacute;n de sus Datos Personales, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p><strong>El uso de tecnolog&iacute;as de rastreo en nuestro portal de internet</strong></p>
        <p>Le informamos que en nuestra p&aacute;gina de internet utilizamos cookies, web beacons u otras tecnolog&iacute;as, a trav&eacute;s de las cuales es posible monitorear su comportamiento como usuario de internet, as&iacute; como brindarle un mejor servicio y experiencia al navegar en nuestra p&aacute;gina.</p>
        <p>&nbsp;</p>
        <p>Los datos que se obtienen con el uso de estas tecnolog&iacute;as ser&aacute;n tratados &uacute;nicamente para personalizar y mejorar el contenido de nuestro sitio de internet, evaluar el uso del mismo, y simplificar su experiencia cuando vuelva a visitarlo.</p>
        <p>&nbsp;</p>
        <p>Es posible deshabilitar estas tecnolog&iacute;as de seguimiento en todo tipo de buscadores; sin embargo, para ejemplificar, se se&ntilde;alan a continuaci&oacute;n los pasos que hay que seguir para deshabilitarlas en Google Chrome: Ir al men&uacute; &ldquo;Herramientas&rdquo;; dar clic en &ldquo;borrar datos de navegaci&oacute;n&rdquo;, seleccionar la opci&oacute;n &ldquo;eliminar cookies y otros datos de sitios y de complementos&rdquo;, dar clic en &ldquo;aceptar&rdquo;. Al deshabilitar o bloquear estos mecanismos se puede ocasionar que el sitio no funcione correctamente.&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>Enlaces de otros sitios de Internet.</strong></p>
        <p>Nuestro sitio de internet puede contener, para su conveniencia, enlaces a otros sitios en Internet que no pertenecen a Promedic&reg;. Promedic&reg; no ha revisado los Avisos de Privacidad de dichos sitios, por lo que no garantiza ni se hace responsable del contenido de dichos, ni del tratamiento que tales sitios den a sus datos personales. Por tanto, le sugerimos que lea cuidadosamente los Avisos de Privacidad de cada uno de los sitios que visita en Internet.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede conocer los cambios en este aviso de privacidad?</strong></p>
        <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras pr&aacute;cticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas</p>
        <p>&nbsp;</p>
        <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a trav&eacute;s del sitio: <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p><strong>Su consentimiento para el tratamiento de sus datos personales</strong></p>
        <p>Le&iacute;do el presente documento y entendiendo el alcance del mismo otorg&oacute; mi consentimiento para que Promedic&reg; de tratamiento a mis datos personales de conformidad con lo establecido en el presente Aviso de Privacidad.</p>
        <p>&nbsp;</p>
        <p>Acepto y reconozco que cualquier modificaci&oacute;n al presente Aviso de Privacidad se me dar&aacute; aviso a trav&eacute;s del sitio web <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p align="right">&nbsp;</p>
        <p align="right">&Uacute;ltima actualizaci&oacute;n: 13 de junio de 2022.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p align="center" id="empleados"><strong>AVISO DE PRIVACIDAD (Empleados)</strong></p>
        <p align="center"><strong>&nbsp;</strong></p>
        <p>M&eacute;dica Profesional Integral, S.A. de C.V. empresa conocida comercialmente como Promedic&reg;, con domicilio en calle Jimenez 465, Piso 9, Colonia Centro, San Pedro Garza Garc&iacute;a, Nuevo Le&oacute;n, C&oacute;digo Postal 66230, y portal de internet <a href="https://promedic.com.mx/">https://promedic.com.mx/</a> es el responsable del uso, tratamiento y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Para qu&eacute; fines utilizaremos sus datos personales? </strong></p>
        <p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son:</p>
        <p>I) Registro de empleados;</p>
        <p>II) Control y administracion del personal;</p>
        <p>III) Control y registro para la asignaci&oacute;n de activos, herramientas de trabajo y asignaci&oacute;n de cuentas y contrase&ntilde;as;</p>
        <p>IV) Administraci&oacute;n y control de expedientes de empleados;</p>
        <p>V) Registro de Accidentes o Incapacidades de empleados;</p>
        <p>VI) Registro de faltas y/o actas administrativas;</p>
        <p>VII) Gesti&oacute;n, control y administraci&oacute;n del pago de salarios y prestaciones laborales;</p>
        <p>VIII) Capacitaci&oacute;n y desarrollo del personal;</p>
        <p>IX) Contactar referencias familiares en caso de emergencias; y</p>
        <p>X) Cualquier otro fin establecido en Materia Laboral.</p>
        <p>.</p>
        <p><strong>&iquest;Qu&eacute; datos personales utilizaremos para estos fines?</strong></p>
        <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>
        <p>I) Datos de identificaci&oacute;n: pudiendo ser nombre, direcci&oacute;n, fecha de nacimiento y/o edad;</p>
        <p>II) Datos familiares: pudiendo ser descendientes, conyugue y/o dependientes econ&oacute;micos, as&iacute; como tel&eacute;fonos de contacto de los mismos;</p>
        <p>III) Datos de referencias: pudiendo ser referencias personales, incluyendo nombre y tel&eacute;fono fijo o m&oacute;vil;</p>
        <p>II) Datos de contacto: pudiendo ser direcci&oacute;n, correo electr&oacute;nico, tel&eacute;fono fijo y/o trabajo y/o m&oacute;vil;</p>
        <p>VI) Datos laborales: pudiendo ser &uacute;ltimos patrones o empleadores, as&iacute; como jefes directos y/o tel&eacute;fonos de oficina y/o correo electr&oacute;nico;</p>
        <p>VII) Datos acad&eacute;micos y profesionales: pudiendo ser estudios de bachillerato, t&eacute;cnicos, licenciatura, maestr&iacute;a y/o doctorado;</p>
        <p>VI) Datos biom&eacute;tricos: pudiendo ser huellas dactilares; y</p>
        <p>&nbsp;</p>
        <p>Adem&aacute;s de los datos personales mencionados anteriormente, para las finalidades informadas en el presente aviso de privacidad utilizaremos los siguientes datos personales considerados como sensibles, que requieren de especial protecci&oacute;n:</p>
        <p>I) Informaci&oacute;n patrimonial: pudiendo ser declaraci&oacute;n patrimonial de bienes muebles o inmuebles;</p>
        <p>II) Datos socio econ&oacute;micos: pudiendo ser ingresos econ&oacute;micos familiares;</p>
        <p>III) Datos de afiliaci&oacute;n sindical, pudiendo ser la incorporaci&oacute;n alg&uacute;n Sindicato;</p>
        <p>IV) Datos de salud: pudiendo ser estado actual de salud;</p>
        <p>VI) Datos de Situaci&oacute;n Jur&iacute;dica: pudiendo ser conciliaciones y/o demandas pasadas o presentes;</p>
        <p>VII) Datos migratorios: pudiendo ser calidad migratoria dentro de los Estados Unidos Mexicanos.</p>
        <p>&nbsp;</p>
        <p>La descripci&oacute;n de los datos personales que ser&aacute;n utilizados y se encuentran enumerados en los p&aacute;rrafos anteriores, son de car&aacute;cter enunciativo mas no limitativo, pudiendo limitarse o ampliarse de acuerdo al perfil del empleado.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;Con qui&eacute;n compartimos su informaci&oacute;n personal y para qu&eacute; fines?</strong></p>
        <p>Le informamos que sus datos personales son compartidos dentro del pa&iacute;s con las siguientes personas, empresas, organizaciones o autoridades distintas a nosotros, para los siguientes fines: Sociedades Controladoras, filiales y/o subsidiarias:&nbsp; con la finalidad de resguardo Centralizado de la Informaci&oacute;n, control de altas, bajas y/o modificaciones, Informe de actividades y Registro de Clientes; Autoridades, Organismos o entidades gubernamentales: Para el cumplimiento a las obligaciones se&ntilde;aladas en la legislaci&oacute;n aplicable en Materia de Salud y/o cumplimiento a requerimientos de la misma autoridad y; Clientes y/o Proveedores: Para el cumplimiento de obligaciones contractuales y comerciales entre el responsable y sus clientes y/o proveedores.</p>
        <p>&nbsp;</p>
        <p>Adicional a lo anterior, le informamos que sus datos personales pueden ser compartidos fuera del pa&iacute;s con Proveedores dedicados al resguardo y/o almacenamiento de la informaci&oacute;n, lo anterior para garantizar la seguridad y protecci&oacute;n de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>El titular reconoce y acepta que Promedic&reg; no requiere autorizaci&oacute;n ni confirmaci&oacute;n del Titular para realizar Transferencia de Datos Personales nacionales o internacionales en los casos previstos en el Art&iacute;culo 37 de la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares.</p>
        <p>&nbsp;</p>
        <p>En todo momento Promedic&reg; realizara las acciones que considere pertinentes con la finalidad de garantizar la protecci&oacute;n y resguardo de sus datos personales.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</strong></p>
        <p>Usted tiene derecho a conocer qu&eacute; datos personales tenemos de usted, para qu&eacute; los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la correcci&oacute;n de su informaci&oacute;n personal en caso de que est&eacute; desactualizada, sea inexacta o incompleta (Rectificaci&oacute;n); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no est&aacute; siendo utilizada adecuadamente (Cancelaci&oacute;n); as&iacute; como oponerse al uso de sus datos personales para fines espec&iacute;ficos (Oposici&oacute;n). Estos derechos se conocen como derechos ARCO.</p>
        <p>&nbsp;</p>
        <p>Para el ejercicio de cualquiera de los derechos ARCO, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntarse la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copia de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Tipo de Derecho ARCO que se pretende ejercer. (Acceso, Rectificaci&oacute;n, Cancelaci&oacute;n u Oposici&oacute;n); IV) Una descripci&oacute;n clara y precisa de lo que pretende ejercer sobre sus Datos Personales; V) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales; VI) En caso de solicitar una rectificaci&oacute;n de datos personales, deber&aacute; de indicar tambi&eacute;n, las modificaciones a realizarse y aportar la documentaci&oacute;n que sustente su petici&oacute;n (donde los datos est&eacute;n correctos) y; VII) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con al Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>Usted puede revocar su consentimiento para el uso de sus datos personales</strong></p>
        <p>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales. Asimismo, usted deber&aacute; considerar que para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando el servicio que nos solicit&oacute;, o la conclusi&oacute;n de su relaci&oacute;n con nosotros.</p>
        <p>&nbsp;</p>
        <p>Para revocar su consentimiento, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p>De conformidad con la Legislaci&oacute;n vigente en materia de Datos Personales su formato de solicitud deber&aacute; estar firmada, contener y adjuntar la siguiente informaci&oacute;n del Titular de los Datos Personales: I) Nombre completo, domicilio completo y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud; II) Adjuntar documentaci&oacute;n que acredite la identidad (copias de credencial para votar, pasaporte o cualquier otra identificaci&oacute;n oficial vigente), en caso de ser representado, el representante adicionalmente deber&aacute; identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificaci&oacute;n oficial vigente del representante, Poder en Escritura P&uacute;blica o Carta Poder Ratificada ante Notario); III) Una descripci&oacute;n clara y precisa de los datos personales respecto de los cuales busca revocar su consentimiento; IV) Adjuntar cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales y; V) La indicaci&oacute;n del lugar donde podremos revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</p>
        <p>&nbsp;</p>
        <p>Su solicitud ser&aacute; contestada mediante correo electr&oacute;nico por parte del Departamento de Datos Personales en un plazo m&aacute;ximo de 20 (veinte) d&iacute;as h&aacute;biles contados desde el d&iacute;a en que se haya recibido. En caso de que la solicitud se conteste de manera positiva o procedente, la petici&oacute;n se podr&aacute; hacer efectiva en un plazo m&aacute;ximo de 15 (quince) d&iacute;as h&aacute;biles. Los plazos se&ntilde;alados en este p&aacute;rrafo podr&aacute;n prorrogarse por el responsable en una sola ocasi&oacute;n y por un periodo igual en caso de ser necesario.</p>
        <p>&nbsp;</p>
        <p>Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, usted podr&aacute; ponerse en contacto con el Departamento de Datos Personales al correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> , quien dar&aacute; tramite a las solicitudes para el ejercicio de estos derechos y atender&aacute; cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
        <p>&nbsp;</p>
        <p>En caso de que la informaci&oacute;n proporcionada sea err&oacute;nea, insuficiente o no se acompa&ntilde;en los documentos de acreditaci&oacute;n correspondiente, el responsable podr&aacute; requerir que aporte la informaci&oacute;n necesaria para dar tr&aacute;mite a su solicitud, contando con un plazo de 5 (cinco) d&iacute;as h&aacute;biles para subsanar el error o la omisi&oacute;n contados a partir del requerimiento del responsable, en caso de que usted no de aporte la informaci&oacute;n solicitada en dicho plazo se tendr&aacute; por no presentada la solicitud y deber&aacute; iniciar de nueva cuenta su solicitud.</p>
        <p>&nbsp;</p>
        <p>El ejercicio de los Derechos ser&aacute; gratuito, previa acreditaci&oacute;n de su identidad, con la excepci&oacute;n de que usted deber&aacute; de cubrir los gastos justificados de envi&oacute; o el costo de reproducci&oacute;n en copias u otros formatos.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede limitar el uso o divulgaci&oacute;n de su informaci&oacute;n personal?</strong></p>
        <p>Para limitar el uso y/o divulgaci&oacute;n de sus Datos Personales, usted deber&aacute; ponerse en contacto con nuestro Departamento de Datos Personales a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico <a href="mailto:datospersonales@empresasaries.com.mx">datospersonales@empresasaries.com.mx</a> donde le ser&aacute; proporcionado el formato de solicitud, requisitos y tiempo de respuesta.</p>
        <p>&nbsp;</p>
        <p><strong>El uso de tecnolog&iacute;as de rastreo en nuestro portal de internet</strong></p>
        <p>Le informamos que en nuestra p&aacute;gina de internet utilizamos cookies, web beacons u otras tecnolog&iacute;as, a trav&eacute;s de las cuales es posible monitorear su comportamiento como usuario de internet, as&iacute; como brindarle un mejor servicio y experiencia al navegar en nuestra p&aacute;gina.</p>
        <p>&nbsp;</p>
        <p>Los datos que se obtienen con el uso de estas tecnolog&iacute;as ser&aacute;n tratados &uacute;nicamente para personalizar y mejorar el contenido de nuestro sitio de internet, evaluar el uso del mismo, y simplificar su experiencia cuando vuelva a visitarlo.</p>
        <p>&nbsp;</p>
        <p>Es posible deshabilitar estas tecnolog&iacute;as de seguimiento en todo tipo de buscadores; sin embargo, para ejemplificar, se se&ntilde;alan a continuaci&oacute;n los pasos que hay que seguir para deshabilitarlas en Google Chrome: Ir al men&uacute; &ldquo;Herramientas&rdquo;; dar clic en &ldquo;borrar datos de navegaci&oacute;n&rdquo;, seleccionar la opci&oacute;n &ldquo;eliminar cookies y otros datos de sitios y de complementos&rdquo;, dar clic en &ldquo;aceptar&rdquo;. Al deshabilitar o bloquear estos mecanismos se puede ocasionar que el sitio no funcione correctamente.&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>Enlaces de otros sitios de Internet.</strong></p>
        <p>Nuestro sitio de internet puede contener, para su conveniencia, enlaces a otros sitios en Internet que no pertenecen a Promedic&reg;. Promedic&reg; no ha revisado los Avisos de Privacidad de dichos sitios, por lo que no garantiza ni se hace responsable del contenido de dichos, ni del tratamiento que tales sitios den a sus datos personales. Por tanto, le sugerimos que lea cuidadosamente los Avisos de Privacidad de cada uno de los sitios que visita en Internet.</p>
        <p>&nbsp;</p>
        <p><strong>&iquest;C&oacute;mo puede conocer los cambios en este aviso de privacidad?</strong></p>
        <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras pr&aacute;cticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas</p>
        <p>&nbsp;</p>
        <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a trav&eacute;s del sitio: <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p><strong>Su consentimiento para el tratamiento de sus datos personales</strong></p>
        <p>Le&iacute;do el presente documento y entendiendo el alcance del mismo otorg&oacute; mi consentimiento para que Promedic&reg; de tratamiento a mis datos personales de conformidad con lo establecido en el presente Aviso de Privacidad.</p>
        <p>&nbsp;</p>
        <p>Acepto y reconozco que cualquier modificaci&oacute;n al presente Aviso de Privacidad se me dar&aacute; aviso a trav&eacute;s del sitio web <a href="https://promedic.com.mx/">https://promedic.com.mx/</a></p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p align="right">&Uacute;ltima actualizaci&oacute;n: 13 de junio de 2022.</p>
        </div>
        </>
    )
}