import NormativoComponent from "../components/NormativoComponent"
import heroplan from "../assets/img/banners/cumplimiento-normativo.png"
import { Helmet } from "react-helmet"

export default function PlanNormativo() {
    return(
        <>  
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Diseñamos un plan de trabajo ADHOC para cada cliente acorde a sus necesidades en apego total al cumplimiento de las normas vigentes en materia de salud ocupacional como lo son:" />
                <title>ProMedic - Cumplimiento Normativo</title>
                <link rel="canonical" href="https://promedic.com.mx/cumplimiento-normativo" />
            </Helmet>
            <img className="w-full" src={heroplan} alt="" />
            <NormativoComponent />
        </>
    )
}