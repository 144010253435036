import { Link } from 'react-router-dom'
import BadgeSeguridad from "../assets/img/Doc-LABORAL.png"
import BadgeLaboral from "../assets/img/DOC-SEGURIDAD.png"

export default function CardsService() {
    return(
        <>
        <div className="grid md:grid-cols-2 gap-10 mx-auto mt-10 mb-10 w-9/12">
            <div className="rounded-lg overflow-hidden bg-[#ecf5ee] text-black shadow-lg">
                <div className="grid md:grid-cols-12 p-6">
                    <img className="col-span-12 mx-auto md:mx-none md:col-span-3" src={BadgeSeguridad} alt="" />
                    <span className="text-gray-400 text-center text-2xl col-span-9 pt-8">
                    ¿Cómo lo Hacemos?
                    </span>
                </div>
                <p className="text-gray-600 hover:text-[#1f7b57] text-sm font-bold text-right pb-4 pr-4"><Link to="/como-lo-hacemos">Ver más →</Link></p>
            </div>
            <div className="rounded-lg overflow-hidden bg-[#ecf5ee] shadow-lg">
                <div className="grid md:grid-cols-12 p-6">
                    <img className="col-span-12 mx-auto md:mx-none md:col-span-3" src={BadgeLaboral} alt="" />
                    <p className="text-gray-400 text-center text-2xl col-span-9 px-1 pt-5">
                    ¿Por qué confiar en nosotros?
                    </p>
                </div>
                <p className="text-gray-600 hover:text-[#1f7b57] text-sm font-bold text-right pb-4 pr-4"><Link to="/nuestra-oferta">Ver más →</Link></p>
            </div>
        </div>
        <div className="grid md:grid-cols-2 gap-10 mx-auto mt-10 mb-10 w-9/12">
            <div className="rounded-lg overflow-hidden bg-[#ecf5ee] text-black shadow-lg">
                <div className="grid grid-cols-12 p-6 ">
                    <img className="col-span-12 mx-auto md:mx-none md:col-span-3" src={BadgeSeguridad} alt="" />
                    <p className="text-gray-400 text-center text-2xl pt-3 col-span-12 md:col-span-9">
                    Algunos de nuestros clientes y entorno ProMedic
                    </p>
                </div>
                <p className="text-gray-600 hover:text-[#1f7b57] text-sm font-bold text-right pb-4 pr-4"><Link to="/clientes">Ver más →</Link></p>
            </div>
            <div className="rounded-lg overflow-hidden bg-[#ecf5ee] shadow-lg">
                <div className="grid md:grid-cols-12 p-6">
                    <img className="col-span-12 mx-auto md:mx-none md:col-span-3" src={BadgeLaboral} alt="" />
                    <p className="text-gray-400 text-center text-2xl pt-3 col-span-9">
                    Campañas de Salud y otras soluciones
                    </p>
                </div>
                <p className="text-gray-600 hover:text-[#1f7b57] text-sm font-bold text-right pb-4 pr-4"><Link to="/otras-soluciones">Ver más →</Link></p>
            </div>
        </div>
        </>
    )
}