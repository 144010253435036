import { useEffect } from "react"

export default function Thanks() {
    
    useEffect(() => {
        document.title = "ProMedic - Gracias por contactarnos"
    }, [])
    
    return (
        <>
            <div className="mt-[5vh] md:mt-[10%] md:mb-[10%] mx-auto">
                <h2 className="text-4xl text-center font-extrabold">¡Gracias por escribirnos! Te contactaremos a la brevedad</h2>
            </div>
        </>
    )
}