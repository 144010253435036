import ContactForm from "../components/ContactForm";
import cntctImg from "../assets/img/banners/contacto.png";
import { Helmet } from "react-helmet";

export default function Contacto() {

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="En Promedic queremos conocerte. Cuéntanos sobre tu proyecto y trabajemos juntos tus objetivos. Rellena el formulario y nuestro equipo estará en contacto contigo." />
          <title>Promedic - Contacto</title>
          <link rel="canonical" href="https://promedic.com.mx/contacto" />
      </Helmet>
      <img className="w-full" src={cntctImg} alt="" />
      <div className="grid md:grid-cols-2 my-6 mx-auto w-9/12">
        <div className="md:border-r-2 md:p-5">
          <h3 className="font-bold text-black text-4xl mb-2">
            Queremos conocerte
          </h3>
          <p>
            Cuéntanos sobre tu proyecto y trabajemos juntos tus objetivos.
            <br />
            <br />
            Rellena el formulario y nuestro equipo estará en contacto contigo.
          </p>
        </div>
        <ContactForm />
      </div>
    </>
  );
}
