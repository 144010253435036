import certBanner from "../assets/img/banners/certificaciones.png"
import Certs from "../components/CardsCertificaciones"
import { Helmet } from "react-helmet"

export default function Certificaciones() {
    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="En Promedic contamos con los distintivos de seguridad sanitaria que nos avalan en el cumplimiento de los lineamientos entorno a COVID 19 en los periodos 2020-2021 y 2021-2022." />
                <title>ProMedic - Certificaciones</title>
                <link rel="canonical" href="https://promedic.com.mx/certificaciones" />
            </Helmet>
            <img className="w-full" src={certBanner} alt="" />
            <Certs />
        </>
    )
}