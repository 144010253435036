import { useState } from "react"
import expediente from "../assets/img/expediente.png"

export default function ExpedienteSection() {

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 bg-[#1f7b57]">
                <div className="bg-[#3e9c55] p-10 md:px-20 pt-14 text-white expediente-l">
                    <h3 className="text-2xl font-bold">Expediente Médico Electrónico</h3>
                    <p>Entregamos resultados mediante un expediente médico electrónico (100% paperless) que puede resguardarse hasta 5 años de acuerdo con la normatividad del expediente clínico en México.</p>
                </div>
                <div className="text-white p-10 md:p-10 md:pl-20 md:ml-10 expediente-r">
                    <ul className="exp-list">
                        Cumplimiento normativo:
                        <li>» Módulos especializados para exámenes médicos ocupacionales masivos.</li>
                        <li>» Informes médicos de atención de urgencia, consulta, accidentes e incapacidades.</li>
                        <li>» Prescripciones digitales.</li>
                        <li>» Reportes personalizados.</li>
                    </ul>
                </div>
            </div>
        </>
    )
}