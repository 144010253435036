import { BrowserRouter, Route, Routes } from "react-router-dom"
import Footer from "./components/Footer"
import Navbar from "./components/Navbar"
import Home from "./pages/Home"
import PlanNormativo from "./pages/PlanNormativo"
import Servicios from "./pages/Servicios"
import ComoLoHacemos from "./pages/ComoLoHacemos"
import Empresas from "./pages/Empresas"
import Certs from "./pages/Certificaciones"
import Contacto from "./pages/Contacto"
import ScrollToTop from "react-scroll-to-top"
import WhatsApp from "./components/WhatsApp"
import NuestraOferta from "./pages/NuestraOferta"
import Clientes from "./pages/Clientes"
import OtrasSoluciones from "./pages/OtrasSoluciones"
import CAMS from "./pages/CAMS"
import AvisoPrivacidad from "./pages/AvisoPrivacidad"
import CulturaOcupacional from "./pages/CulturaOcupacional"
import Nom30 from "./pages/Nom30"
import RiesgoPsicosocial from "./pages/RiesgoPsicosocial"
import AccidentesTrabajo from "./pages/AccidentesTrabajo"
import Thanks from "./pages/Thanks"

export default function App() {

  return (
    <BrowserRouter>
      <ScrollToTop smooth style={{ background: "#3e9c55", padding: "5px", borderRadius: "50%", top: "90.5%", left: "5%" }} color="white" />
      <WhatsApp />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="servicios" element={<Servicios />} />
        <Route path="empresas" element={<Empresas />} />
        <Route path="como-lo-hacemos" element={<ComoLoHacemos />} />
        <Route path="nuestra-oferta" element={<NuestraOferta />} />
        <Route path="clientes" element={<Clientes />} />
        <Route path="otras-soluciones" element={<OtrasSoluciones />} />
        <Route path="cumplimiento-normativo" element={<PlanNormativo />}></Route>
        <Route path="certificaciones" element={<Certs />}></Route>
        <Route path="centros-atencion-medica" element={<CAMS />}></Route>
        <Route path="contacto" element={<Contacto />}></Route>
        <Route path="aviso-privacidad" element={<AvisoPrivacidad />}></Route>
        <Route path="cultura-salud-ocupacional" element={<CulturaOcupacional />}></Route>
        <Route path="nom-30" element={<Nom30 />}></Route>
        <Route path="factores-riesgo-psicosocial" element={<RiesgoPsicosocial />}></Route>
        <Route path="principales-accidentes-en-trabajo" element={<AccidentesTrabajo />}></Route>
        <Route path="thank-you" element={<Thanks />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

