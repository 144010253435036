import { useState } from "react"
import BadgeSeguridad from "../assets/img/Doc-LABORAL.png"
import BadgeLaboral from "../assets/img/DOC-SEGURIDAD.png"
import BadgeElssa from "../assets/img/doc-elssa.png"
import Cert from "../assets/img/cert1.png"
import Cert2 from "../assets/img/cert2.png"
import Cert3 from "../assets/img/cert3.jpg"

export default function Certs() {
    const [showModal, setShowModal] = useState(false)
    const [showModal2, setShowModal2] = useState(false)
    const [showModal3, setShowModal3] = useState(false)
    return(
        <>
        <div className="my-6 mx-auto w-10/12 ">
            <h1 className="font-bold text-black text-4xl mb-2">Certificaciones</h1>
            <p className="text-lg">La pandemia ha obligado a que las empresas e instituciones tengan protocolos más estrictos de seguridad y salud ocupacional.
            <br />
            <br />
            Nosotros contamos con los <b>distintivos de seguridad sanitaria</b> que nos <b>avalan en el cumplimiento de los lineamientos entorno a COVID 19 en los periodos 2020-2021 y 2021-2022.</b>
            <br />
            <br />
            <b>Distintivo en Entornos Laborales Seguros y Saludables “ELSSA”</b>, otorgado por el Instituto Mexicano del Seguro Social en 2022.</p>
        </div>
        <div className="grid md:grid-cols-2 gap-10 mx-auto mt-10 mb-10 w-9/12">
            <div className="rounded-lg overflow-hidden bg-[#ecf5ee] text-black shadow-lg">
                <div className="grid md:grid-cols-12 p-6">
                    <img className="md:col-span-3 mx-auto" src={BadgeSeguridad} alt="" />
                    <span className="text-gray-400 text-justify text-base md:col-span-9 px-2 pt-3">
                    Nosotros contamos con los <b>distintivos de seguridad sanitaria</b> que nos <b>avalan en el cumplimiento de los lineamientos entorno a COVID 19 en los periodos 2020-2021 y 2021-2022</b>.
                    </span>
                </div>
                <p className="hover:cursor-pointer hover:text-black px-10 py-3 text-[#1f7b57] text-right" onClick={() => setShowModal(true)}>Ver documento</p>
            </div>
            <div className="rounded-lg overflow-hidden bg-[#ecf5ee] text-black shadow-lg">
                <div className="grid md:grid-cols-12 p-6">
                    <img className="md:col-span-3 mx-auto" src={BadgeLaboral} alt="" />
                    <span className="text-gray-400 text-justify text-base md:col-span-9 px-2 pt-3">
                    <b>Distintivo en Entornos Laborales Seguros y Saludables “ELSSA”</b>, otorgado por el Instituto Mexicano del Seguro Social en 2022.
                    </span>
                </div>
                <p className="hover:cursor-pointer hover:text-black px-10 py-3 text-[#1f7b57] text-right" onClick={() => setShowModal2(true)}>Ver documento</p>
            </div>
            <div className="rounded-lg overflow-hidden bg-[#ecf5ee] text-black shadow-lg">
                <div className="grid md:grid-cols-12 p-6">
                    <img className="md:col-span-3 mx-auto" src={BadgeElssa} alt="" />
                    <span className="text-gray-400 text-justify text-base md:col-span-9 px-2 pt-3">
                    ¿Por qué es importante contar con el <b>distintivo ELSSA</b>?
                    </span>
                </div>
                <p className="hover:cursor-pointer hover:text-black px-10 py-3 text-[#1f7b57] text-right" onClick={() => setShowModal3(true)}>Ver documento</p>
            </div>
        </div>
            {showModal ? (
            <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-[#ecf5ee] outline-none focus:outline-none">
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                        <img src={Cert} className="w-96" alt="" />
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                    >
                        Cerrar
                    </button>
                    </div>
                </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        ) : null}
        {showModal2 ? (
            <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-[#ecf5ee] outline-none focus:outline-none">
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                        <img src={Cert2} className="w-96" alt="" />
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal2(false)}
                    >
                        Cerrar
                    </button>
                    </div>
                </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        ) : null}
        {showModal3 ? (
            <>
            <div
                className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto md:mt-[170vh] my-6 mx-auto">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-[#ecf5ee] outline-none focus:outline-none">
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                        <img src={Cert3} className="w-full" alt="" />
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal3(false)}
                    >
                        Cerrar
                    </button>
                    </div>
                </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        ) : null}
        </>
    )
}