import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import "swiper/css"
import cat1 from "../assets/img/categorias/consulta.svg"
import cat2 from "../assets/img/categorias/analisis.svg"
import cat3 from "../assets/img/categorias/audio.svg"
import cat4 from "../assets/img/categorias/rayos-x.svg"
import cat5 from "../assets/img/categorias/espirometria.svg"
import cat6 from "../assets/img/categorias/procedimientos.svg"
import cat7 from "../assets/img/categorias/electro.svg"

export default function CardsHome() {
    return (
        <>  
            <div className="bg-[#f8f8f8] opacity-90">
                <h4 className="font-bold text-3xl text-center pt-12">Categorías de Estudios que ofrecemos para ti:</h4>
                <Swiper
                    spaceBetween={30}
                    navigation={true}
                    breakpoints={{
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        }
                    }}
                    modules={[Navigation]}
                    className="mySwiper p-10 w-10/12 mx-auto"
                >
                    <SwiperSlide>
                        <div className="max-w-sm rounded-lg h-[250px] overflow-hidden bg-white text-black shadow-lg">
                            <div className="px-6 py-4">
                                <div className="mb-1">
                                    <img src={cat1} alt="" />
                                </div>
                                <h4 className="text-gray-700 font-bold pt-3 text-xl">Consulta Médica 24/7</h4>
                                <p className="text-gray-700 text-sm pt-3">
                                Atención médica disponible las 24 horas del día, los 7 días de la semana.
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="max-w-sm rounded-lg h-[250px] overflow-hidden bg-white text-black shadow-lg">
                            <div className="px-6 py-4">
                                <div className="mb-1">
                                    <img src={cat2} alt="" />
                                </div>
                                <h4 className="text-gray-700 font-bold pt-3 text-xl">Análisis Clínicos</h4>
                                <p className="text-gray-700 text-sm pt-3">
                                Pruebas de laboratorio para diagnósticos precisos y oportunos.
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="max-w-sm rounded-lg h-[250px] overflow-hidden bg-white text-black shadow-lg">
                            <div className="px-6 py-4">
                                <div className="mb-1">
                                    <img src={cat3} alt="" />
                                </div>
                                <h4 className="text-gray-700 font-bold pt-3 text-xl">Audiometría</h4>
                                <p className="text-gray-700 text-sm pt-3">
                                Evaluación completa de la salud auditiva para detectar problemas de audición.
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="max-w-sm rounded-lg h-[250px] overflow-hidden bg-white text-black shadow-lg">
                            <div className="px-6 py-4">
                                <div className="mb-1">
                                    <img src={cat4} alt="" />
                                </div>
                                <h4 className="text-gray-700 font-bold pt-3 text-xl">Rayos X</h4>
                                <p className="text-gray-700 text-sm pt-3">
                                Servicios de radiografía digital para diagnósticos rápidos y efectivos.
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="max-w-sm rounded-lg h-[250px] overflow-hidden bg-white text-black shadow-lg">
                            <div className="px-6 py-4">
                                <div className="mb-1">
                                    <img src={cat5} alt="" />
                                </div>
                                <h4 className="text-gray-700 font-bold pt-3 text-xl">Espirometría</h4>
                                <p className="text-gray-700 text-sm pt-3">
                                Pruebas respiratorias para medir la función pulmonar y detectar enfermedades respiratorias.
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="max-w-sm rounded-lg h-[250px] overflow-hidden bg-white text-black shadow-lg">
                            <div className="px-6 py-4">
                                <div className="mb-1">
                                    <img src={cat6} alt="" />
                                </div>
                                <h4 className="text-gray-700 font-bold pt-3 text-lg">Procedimientos Menores</h4>
                                <p className="text-gray-700 text-sm pt-3">
                                Realización de procedimientos básicos como suturas, inyecciones y aplicaciones de suero.
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="max-w-sm rounded-lg h-[250px] overflow-hidden bg-white text-black shadow-lg">
                            <div className="px-6 py-4">
                                <div className="mb-1">
                                    <img src={cat7} alt="" />
                                </div>
                                <h4 className="text-gray-700 font-bold pt-3 text-xl">Electrocardiograma</h4>
                                <p className="text-gray-700 text-sm pt-3">
                                Monitoreo y evaluación del ritmo y la actividad eléctrica del corazón para detectar anomalías.
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    )
}