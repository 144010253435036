import { useState} from "react"
import Frame from "./Frame"

const Tabs = ({ color }) => {
  const [openTab, setOpenTab] = useState(1);
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-9/12 mx-auto mt-10">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-lg font-bold px-5 py-3 rounded block leading-normal " +
                  (openTab === 1
                    ? "bg-" + color + "-600"
                    : "text-" + "[#1f7b57]")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Santa Catarina
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-lg font-bold px-5 py-3 rounded block leading-normal " +
                  (openTab === 2
                    ? "bg-" + color + "-600"
                    : "text-" + "[#1f7b57]")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                San Nicolás
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-lg font-bold px-5 py-3 rounded block leading-normal " +
                  (openTab === 3
                    ? "bg-" + color + "-600"
                    : "text-" + "[#1f7b57]")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                Apodaca
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-lg font-bold px-5 py-3 rounded block leading-normal " +
                  (openTab === 4
                    ? "bg-" + color + "-600"
                    : "text-" + "[#1f7b57]")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(4);
                }}
                data-toggle="tab"
                href="#link4"
                role="tablist"
              >
                Ciénega de Flores
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-lg font-bold px-5 py-3 rounded block leading-normal " +
                  (openTab === 5
                    ? "bg-" + color + "-600"
                    : "text-" + "[#1f7b57]")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(5);
                }}
                data-toggle="tab"
                href="#link5"
                role="tablist"
              >
                Ramos Arizpe
              </a>
            </li>
          </ul>
          <hr />
          <br />
          <div className="relative flex flex-col min-w-0 break-words bg-[#ecf5ee] w-full mb-6 rounded-lg">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <div className="grid md:grid-cols-2">
                    <iFrame
                      src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3595.776143272313!2d-100.4819869!3d25.6787144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866298624b6425eb%3A0x837b314c17d9aa71!2sC.%20Segunda%20111%2C%20Luis%20Echeverr%C3%ADa%2C%2066358%20Santa%20Catarina%2C%20N.L.!5e0!3m2!1ses-419!2smx!4v1666103935742!5m2!1ses-419!2smx`}
                      height={300}
                      width={`100%`}
                    />
                    <p className="mx-auto my-20">
                      <span className="text-2xl font-bold">
                        Centro Médico Santa Catarina
                      </span>
                      <br />
                      <br />
                      C. Segunda 111,
                      <br />
                      Luis Echeverría,
                      <br />
                      66358 Santa Catarina, N.L.
                    </p>
                  </div>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <div className="grid md:grid-cols-2">
                    <iFrame
                      src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3594.2548462724876!2d-100.2761247!3d25.7290846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866294cb2c1b0b21%3A0x266e2612882b9419!2sAv.%20Diego%20D%C3%ADaz%20de%20Berlanga%20400%2C%20Sin%20Nombre%20de%20Col%202%2C%20Las%20Puentes%201%205o%20Sector%2C%20San%20Nicol%C3%A1s%20de%20los%20Garza%2C%20N.L.!5e0!3m2!1ses-419!2smx!4v1666105464996!5m2!1ses-419!2smx`}
                      height={300}
                      width={`100%`}
                    />
                    <p className="mx-auto px-5 md:px-10 my-20">
                      <span className="text-2xl font-bold">
                        Centro Médico San Nicolás
                      </span>
                      <br />
                      <br />
                      Av. Diego Díaz de Berlanga 400, Sin Nombre de Col 2, Las
                      Puentes 1er Sector,
                      <br />
                      C.P.: 66444.
                      <br />
                      San Nicolás de los Garza, N.L.
                      <br />
                    </p>
                  </div>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <div className="grid md:grid-cols-2">
                    <iFrame
                      src={`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14376.350281993253!2d-100.2034677!3d25.7346175!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x63d4a8cc1d9d50c6!2sProMedic%20CAM%20Apodaca!5e0!3m2!1ses-419!2smx!4v1666106074065!5m2!1ses-419!2smx`}
                      height={300}
                      width={`100%`}
                    />
                    <p className="mx-auto px-5 my-20">
                      <span className="text-2xl font-bold">
                        Centro Médico Apodaca
                      </span>
                      <br />
                      <br />
                      Av. Ignacio Sepúlveda #204,
                      <br />
                      C. La Encarnación, 66633
                      <br />
                      CD. Apodaca, N.L
                      <br />
                    </p>
                  </div>
                </div>
                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                  <div className="grid md:grid-cols-2">
                    <iFrame
                      src={`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14355.923367792668!2d-100.2242155!3d25.9030003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe71dc6392540d9d6!2sPromedic%20Cienega%20De%20Flores!5e0!3m2!1ses-419!2smx!4v1666107014747!5m2!1ses-419!2smx`}
                      height={300}
                      width={`100%`}
                    />
                    <p className="mx-auto px-5 my-20">
                      <span className="text-2xl font-bold">
                        Centro Médico Ciénega de Flores
                      </span>
                      <br />
                      <br />
                      Nuevo Laredo - Monterrey,
                      <br />
                      65766 Monterrey, N.L
                      <br />
                    </p>
                  </div>
                </div>
                <div className={openTab === 5 ? "block" : "hidden"} id="link5">
                  <div className="grid md:grid-cols-2">
                    <iFrame
                      src={`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d522.194250520766!2d-100.94668395420565!3d25.538039729691683!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86881571d67ace0b%3A0x2ccd4df19077bbfa!2sLaboratorios%20Dr.%20Moreira%20-%20Ramos%20Arizpe!5e0!3m2!1ses-419!2smx!4v1713896127861!5m2!1ses-419!2smx`}
                      height={300}
                      width={`100%`}
                    />
                    <p className="mx-auto px-5 my-20 text-center">
                      <span className="text-2xl font-bold">
                        Centro Médico Ramos Arizpe
                      </span>
                      <br />
                      <br />
                      Blvrd Manuel Acuña 400, Eulalio Gutiérrez, 25903 Ramos
                      Arizpe, Coah.
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function TabsRender() {
  return (
    <>
        <div className="mx-auto w-9/12 ">
            <h2 className="font-bold text-black text-4xl mt-10 mb-2">CAM's</h2>
            <p className="text-lg">A través de los Centros de Atención Médica ubicadas en las inmediaciones de las principales zonas industriales de Monterrey; ofrecemos a todos nuestros clientes un servicio vanguardista e integral para impactar positivamente en la salud de su fuerza laboral y familiares directo.</p>
        </div>
        <Tabs />
    </>
  )
}