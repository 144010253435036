import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import Icoh from '../assets/img/logo-companies/promedic1.svg'
import Som from '../assets/img/logo-companies/promedic2.svg'
import Fenastac from '../assets/img/logo-companies/promedic3.svg'
import Caohc from '../assets/img/logo-companies/promedic4.svg'
import Niosh from '../assets/img/logo-companies/promedic5.svg'

import { Autoplay, Pagination, Navigation } from "swiper"

export default function SliderLogos() {
    return (
        <>
            <h2 className="text-center mt-10 pb-5 text-3xl font-bold md:ml-28 md:text-left">Equipo de Expertos</h2>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                navigation={true}
                autoplay={{
                    delay: 8000,
                    disableOnInteraction: true,
                }}
                breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    768: {
                      slidesPerView: 4,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 40,
                    },
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="logoSwiper mx-auto text-center w-11/12 md:w-9/12"
            >
                <div className="grid grid-cols-3">
                    <SwiperSlide>
                        <img className="logo-company" src={Icoh} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img className="logo-company" src={Som} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img className="logo-company" src={Fenastac} alt="" />
                    </SwiperSlide>
                    <SwiperSlide className="mt-10">
                        <img className="logo-company" src={Caohc} alt="" />
                    </SwiperSlide>
                    <SwiperSlide className="mt-10">
                        <img className="logo-company" src={Niosh} alt="" />
                    </SwiperSlide>
                </div>
            </Swiper>
            <p className="text-center w-10/12 mx-auto pt-5 pb-10 text-sm">Equipo multidisciplinario con expertos en diferentes áreas de salud ocupacional, miembros activos de las principales sociedades en el campo y certificados en estándares internacionales, en actualización permanente en área clínica, tecnológica y seguridad, lo que nos permite integrar todos nuestros servicios orientados en el máximo beneficio de nuestros clientes y sus trabajadores</p>
        </>
    )
}