import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function WhatsApp() {
  return (
    <div>
      <a
        style={{ color: "white" }}
        href="//wa.me/+528118022122?text="
        className="float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faWhatsapp} className="my-float ml-4"></FontAwesomeIcon>
      </a>
    </div>
  );
}