import camsImg from "../assets/img/banners/cams.png" 
import TabsRender from "../components/Tabs"
import { Helmet } from "react-helmet"

export default function Contacto() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="A través de los Centros de Atención Médica de Promedic ubicadas en las inmediaciones de las principales zonas industriales de Monterrey; ofrecemos a todos nuestros clientes un servicio vanguardista e integral para impactar positivamente en la salud de su fuerza laboral y familiares directo." />
                <title>ProMedic - Centros de Atención Médica</title>
                <link rel="canonical" href="https://promedic.com.mx/centros-atencion-medica" />
            </Helmet>
            <img className="w-full" src={camsImg} alt="" />
            <div className="my-6 mx-auto">
                <TabsRender />
            </div>
        </>
    )
}