export default function PlanNormativo() {
    return(
        <>
            <div className="my-6 mx-auto w-10/12 ">
                <h1 className="font-bold text-black text-4xl mb-2">Cumplimiento Normativo</h1>
                <p className="text-lg">Diseñamos un plan de trabajo ADHOC para cada cliente acorde a sus necesidades en apego total al cumplimiento de las normas vigentes en materia de salud ocupacional como lo son:</p>
            </div>
            <div className="grid md:grid-cols-2 bg-[#ecf5ee] w-10/12 md:w-8/12 mt-10 mb-10 mx-auto p-10 rounded-lg">
                <div>
                    <p>
                    <span className="font-bold ">LEY FEDERAL DEL TRABAJO TÍTULO 9°<br />
                    LEY GENERAL DE SALUD</span> 
                    <br />
                    <br />
                    <span className="font-bold">NOM 030 STPS 2009</span>
                    <br />
                    “Servicios preventivos de seguridad y salud en el trabajo”.
                    <br />
                    <br />
                    <span className="font-bold">NOM 035 STPS 2018</span><br />
                    “Identificación, análisis y prevención de factores de riesgo psicosocial”.
                    </p>
                </div>
                <div className="md:border-l mt-5 md:mt-0 border-black md:pl-8">
                    <p>
                    <span className="font-bold">NOM 036 STPS 2018</span><br />
                    “Factores de riesgo ergonómico en el trabajo”.
                    <br />
                    <br />
                    <span className="font-bold ">NOM 004 SSA3 2012</span><br />
                    Del expediente clínico.
                    <br />
                    <br />
                    <span className="font-bold">NOM 034 SSA3 2013</span><br />
                    Regulación de los servicios de salud y atención médica prehospitalaria.
                    <br />
                    <br />
                    <span className="font-bold">NOM 251 SSA1 2009</span><br />
                    Prácticas de higiene para el proceso de alimentos, bebidas o suplementos alimenticios
                    </p>
                </div>
            </div>
        </>
    )
}