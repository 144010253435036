import Hero from "../components/Hero"
import Categories from "../components/Categories"
import { Helmet } from "react-helmet"

export default function Home() {

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Somos una empresa dedicada a brindar servicios integrales de salud ocupacional mediante la adaptación de tecnologías en base al cumplimiento normativo, siguiendo tendencias internacionales con el objetivo de llevar las mejores prácticas en Salud Ocupacional aumentando sinérgicamente la productividad y bienestar con nuestros socios comerciales." />
                <title>ProMedic</title>
                <link rel="canonical" href="https://promedic.com.mx/" />
            </Helmet>
            <Hero />
            <Categories />
        </>
    )
}