import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Hero from "../components/Hero"
import solutionsImg from "../assets/img/general-banner-promedic.png"
import saludImg from "../assets/img/campsalud.png"
import solution1 from "../assets/img/soluciones/imagen5.png"
import solution2 from "../assets/img/soluciones/imagen6.png"
import solution3 from "../assets/img/soluciones/imagen7.png"

export default function OtrasSoluciones() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "ProMedic - Clientes y Entorno ProMedic";
    }, []);

    return(
        <>
        <Hero img={solutionsImg} position="relative" />
        <p onClick={() => navigate(-1)} className="text-[#1f7b57] hover:cursor-pointer hover:text-gray-700 text-sm mt-[20vh] md:mt-[34%] mx-auto w-9/12 mb-5">Volver a Servicios</p>
        <div className="grid md:grid-cols-2 mx-auto w-9/12">
            <div className="md:border-r-2 md:pr-5">
                <h1 className="font-bold text-black text-4xl mb-2">Campañas de Salud</h1>
                <p>Con el objetivo de acercar a sus colaboradores hacia una cultura preventiva y detectar factores de riesgo en su fuerza laboral acompañamos a cada uno de nuestros clientes en la promoción de diversas campañas en salud ocupacional acorde a sus necesidades.</p>
            </div>
            <img src={saludImg} className="w-[85%]" alt="" />
        </div>
        <h3 className="font-bold text-black text-4xl my-4 mx-auto w-9/12">Otras soluciones</h3>
        <p className="mx-auto w-9/12">Contamos con una amplia experiencia en el manejo integral de Residuos Peligrosos Biológicos Infecciosos (R.P.B.I).</p>
        <div className="grid md:grid-cols-3 gap-10 md:gap-0 my-14 mx-auto w-9/12">
            <div className="text-center">
                <img className="mx-auto" src={solution1} alt="" />
                <p>Recolección</p>
            </div>
            <div className="text-center">
                <img className="mx-auto" src={solution2} alt="" />
                <p>Transporatación</p>
            </div>
            <div className="text-center">
                <img className="mx-auto" src={solution3} alt="" />
                <p>Tratamiento y disposición final</p>
            </div>
        </div>
        </>
    )
}