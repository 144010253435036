import heroservicios from "../assets/img/banners/servicios.png"
import ServiciosComponent from "../components/ServiciosComponent"
import CardsService from "../components/CardsService"
import { Helmet } from "react-helmet"


export default function Servicios() {
    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="En Promedic contamos con los siguientes servicios tanto para pacientes como empresas." />
                <title>ProMedic - Servicios</title>
                <link rel="canonical" href="https://promedic.com.mx/servicios" />
            </Helmet>
            <img className="w-full" src={heroservicios} alt="" />
            <ServiciosComponent />
            <CardsService />
        </>
    )
}