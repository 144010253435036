import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

export default function FormHome() {

    const [toggle, setToggle] = useState(false);
    const [captchaValid, setCaptchaValid] = useState(null);

    // Hook de Formulario
    const form = useRef();
    const navigate = useNavigate();

    const sendEmail = (e) => {

        if (captcha.current.getValue()) {
            setCaptchaValid(true);
            navigate('/thank-you');
        } else {
        setCaptchaValid(false);
        e.preventDefault();
        }

        emailjs
        .sendForm(
            "service_jvjl55d",
            "template_ge4a6t6",
            form.current,
            "khoW7wKziiJkyWouZ"
        );
    };

    const captcha = useRef(null);

    const onChange = () => {
        if (captcha.current.getValue()) {
            setCaptchaValid(true);
        }
    };

    // Hook de Toggle Form
    const handleClick = () => {
        setToggle(!toggle);
    }

    return (
      <>
        <div className="text-center my-8 mx-12 md:mx-28 md:block">
          <button
            onClick={handleClick}
            className="border-2 py-1 w-full rounded-lg font-bold text-green-700 text-xl md:text-3xl border-emerald-700"
          >
            Trabajemos Juntos{" "}
            <FontAwesomeIcon className="text-base text-xl md:text-2xl" icon={faChevronDown} />
          </button>
        </div>
        {toggle && (
          <div className="md:block md:relative md:mt-0">
            <div className="px-4 py-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-24 lg:py-8">
              <div className="flex flex-col items-end x-l:flex-row">
                <div className="w-full">
                  <div className="bg-[#3e9c55] rounded-lg shadow-2xl p-4 sm:p-5">
                    <form ref={form} onSubmit={sendEmail}>
                      <div className="mb-1 sm:mb-2">
                        <label
                          htmlFor="name"
                          className="inline-block text-white mb-1 font-medium"
                        >
                          Nombre Completo
                        </label>
                        <input
                          placeholder="Nombre Completo"
                          required
                          type="text"
                          className="flex-grow w-full h-8 px-4 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                          id="name"
                          name="name"
                        />
                      </div>
                      <div className="mb-1 sm:mb-2">
                        <label
                          htmlFor="email"
                          className="inline-block text-white mb-1 font-medium"
                        >
                          E-mail
                        </label>
                        <input
                          placeholder="mail@promedic.com.mx"
                          required
                          type="email"
                          className="flex-grow w-full h-8 px-4 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                          id="email"
                          name="email"
                        />
                      </div>
                      <div className="flex gap-5 mb-1 sm:mb-2">
                        <div className="w-full">
                          <label
                            htmlFor="company"
                            className="inline-block text-white mb-1 font-medium"
                          >
                            Empresa
                          </label>
                          <input
                            placeholder="Empresa"
                            required
                            type="text"
                            className="flex-grow w-full h-8 px-4 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                            id="company"
                            name="company"
                          />
                        </div>
                        <div className="w-full">
                          <label
                            htmlFor="phone"
                            className="inline-block text-white mb-1 font-medium"
                          >
                            Teléfono
                          </label>
                          <input
                            placeholder="55 5555 5555"
                            required
                            type="tel"
                            className="flex-grow w-full h-8 px-4 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                            id="phone"
                            name="phone"
                          />
                        </div>
                      </div>
                      <div className="flex gap-5 mb-1 sm:mb-2">
                        <div className="w-full">
                          <label
                            htmlFor="state"
                            className="inline-block text-white mb-1 font-medium"
                          >
                            Estado
                          </label>
                          <select
                            required
                            className="flex-grow w-full h-8 px-3 bg-white border border-gray-300 rounded shadow-sm focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline text-stone-400"
                            id="state"
                            name="state"
                          >
                            <option selected>Elige un estado</option>
                            <option>Aguascalientes</option>
                            <option>Baja California</option>
                            <option>Baja California Sur</option>
                            <option>Campeche</option>
                            <option>Ciudad de México</option>
                            <option>Coahuila</option>
                            <option>Colima</option>
                            <option>Chiapas</option>
                            <option>Chihuahua</option>
                            <option>Durango</option>
                            <option>Guanajuato</option>
                            <option>Guerrero</option>
                            <option>Hidalgo</option>
                            <option>Michoacán</option>
                            <option>Morelos</option>
                            <option>Nayarit</option>
                            <option>Nuevo León</option>
                            <option>Oaxaca</option>
                            <option>Puebla</option>
                            <option>Querétaro</option>
                            <option>Quintana Roo</option>
                            <option>San Luis Potosí</option>
                            <option>Sinaloa</option>
                            <option>Sonora</option>
                            <option>Tabasco</option>
                            <option>Tamaulipas</option>
                            <option>Tlaxcala</option>
                            <option>Veracruz</option>
                            <option>Yucatán</option>
                            <option>Zacatecas</option>
                          </select>
                        </div>
                        <div className="w-full">
                          <label
                            htmlFor="city"
                            className="inline-block text-white mb-1 font-medium"
                          >
                            Municipio
                          </label>
                          <input
                            placeholder="Municipio"
                            required
                            type="textarea"
                            className="flex-grow w-full h-8 px-4 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                            id="city"
                            name="city"
                          />
                        </div>
                      </div>
                      <div className="mb-1 sm:mb-2">
                        <label
                          htmlFor="message"
                          className="inline-block text-white mb-1 font-medium"
                        >
                          Mensaje
                        </label>
                        <input
                          placeholder="Deja tu mensaje"
                          required
                          type="textarea"
                          className="flex-grow w-full h-16 px-4 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                          id="message"
                          name="message"
                        />
                      </div>
                      <div className="mt-4 mb-2 sm:mb-4">
                        <button
                          type="submit"
                          className="inline-flex items-center justify-center w-full h-9 mt-2 font-medium bg-white text-green-700 transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                          ref={form}
                          onSubmit={sendEmail}
                        >
                          Enviar
                        </button>
                      </div>
                      <div className="recaptcha mt-5">
                        <ReCAPTCHA
                          ref={captcha}
                          sitekey="6LeY-8ciAAAAAIUiC6vxupVtSIWWY2_ps07bKEFd"
                          onChange={onChange}
                        />
                      </div>
                      {captchaValid === false && (
                        <div className="error-captcha text-red-700">
                          Verifica el captcha
                        </div>
                      )}
                      <div className="form-check">
                        <input
                          class="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          required
                        ></input>
                        <label
                          className="form-check-label inline-block text-white text-sm"
                          htmlFor="flexCheckChecked"
                        >
                          *Acepto los términos y condiciones
                        </label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
}