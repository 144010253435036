export default function HowCard({ img, description }) {
  return (
    <>
      <div className="grid bg-[#3e9c55] p-5 rounded-lg h-40 md:grid-cols-3 text-white">
        <img className="pt-2" src={img} alt="" />
        <p className="col-span-2">{description}</p>
      </div>
    </>
  )
}
