import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/img/LOGO-color-promedic.svg"
import stripes from "../assets/img/stripes.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between">
        <div className="container flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <NavLink to="/">
              <img src={stripes} className="stripes-nav" alt="" />
              <img className="logo" src={logo} alt="" />
            </NavLink>
            <button
              className="text-[#3e9c55] cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}>
              <FontAwesomeIcon icon={faBars} className="text-2xl"></FontAwesomeIcon>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger">
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'text-[#1f7b57] px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug' : 'px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75'
                  }
                  to="/empresas">
                  <span className="ml-2">Empresas</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'text-[#1f7b57] px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug' : 'px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75'
                  }
                  to="/servicios">
                  <span className="ml-2">Servicios</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'text-[#1f7b57] px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug' : 'px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75'
                  }
                  to="/cumplimiento-normativo">
                  <span className="ml-2">Cumplimiento Normativo</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'text-[#1f7b57] px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug' : 'px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75'
                  }
                  to="/certificaciones">
                  <span className="ml-2">Certificaciones</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'text-[#1f7b57] px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug' : 'px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75'
                  }
                  to="/centros-atencion-medica">
                  <span className="ml-2">CAMs</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'text-[#1f7b57] px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug' : 'px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75'
                  }
                  to="/contacto">
                  <span className="ml-2">Contacto</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}