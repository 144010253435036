import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import servicios1 from "../assets/img/servicios.svg"
import servicios2 from "../assets/img/servicios2.svg"
import servicios3 from "../assets/img/servicios3.svg"
import servicios4 from "../assets/img/servicios4.svg"

export default function CardsHome() {
    return (
        <>  
            <div className="bg-[#f8f8f8] opacity-90">
                <h4 className="font-bold text-3xl text-center pt-12">Registro  de Prestadores de Servicios Especializados <span className="text-[#3e9c55]">(REPSE)</span></h4>
                {/* <div className="grid grid-cols-1 rounded mx-10 gap-5 py-10 w-12/12 md:grid-cols-4 md:w-9/12 md:mx-auto"></div> */}
                <Swiper
                    spaceBetween={30}
                    breakpoints={{
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        }
                    }}
                    className="mySwiper p-10 w-10/12 mx-auto"
                >
                    <SwiperSlide>
                        <div className="max-w-sm rounded-lg h-[250px] overflow-hidden bg-white text-black shadow-lg">
                            <div className="px-6 py-4">
                                <div className="mb-1">
                                    <img src={servicios1} alt="" />
                                </div>
                                <p className="text-gray-700 text-sm pt-3">
                                Al adquirir servicios especializados, aumentas la competitividad de la empresa y su productividad pues se disminuyen cargas administrativas.
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="max-w-sm rounded-lg h-[250px] overflow-hidden bg-white text-black shadow-lg">
                            <div className="px-6 py-4">
                                <div className="mb-1">
                                    <img src={servicios2} alt="" />
                                </div>
                                <p className="text-gray-700 text-sm pt-3">
                                Al adquirir servicios especializados, aumentas la competitividad de la empresa y su productividad pues se disminuyen cargas administrativas.
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="max-w-sm rounded-lg h-[250px] overflow-hidden bg-white text-black shadow-lg">
                            <div className="px-6 py-4">
                                <div className="mb-1">
                                    <img src={servicios3} alt="" />
                                </div>
                                <p className="text-gray-700 text-sm pt-3">
                                Somos una empresa con autorización para la prestación del servicio en salud ocupacional evitando caer en incumplimiento normativo.
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="max-w-sm rounded-lg h-[250px] overflow-hidden bg-white text-black shadow-lg">
                            <div className="px-6 py-4">
                                <p className="mb-1">
                                    <img src={servicios4} alt="" />
                                </p>
                                <p className="text-gray-700 text-sm pt-3">
                                Beneficio fiscal al poder deducir facturas por servicios regulados por la Secretaría del Trabajo y Previsión Social.
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    )
}