import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import banner from "../assets/img/hero/hero-promedic-min.png"

import { Autoplay, Pagination, Navigation } from "swiper"

export default function Hero() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                navigation={false}
                loop={false}
                autoplay={{
                    delay: 8000,
                    disableOnInteraction: true,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="block text-center w-full"
            >
                <div className="">
                    <SwiperSlide>
                        <img src={banner} alt="Promoción Promedic" />
                    </SwiperSlide>
                </div>
            </Swiper>
        </>
    )
}