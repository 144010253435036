import post from "../assets/img/blog/nom30_promedic.png"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet';

export default function Nom30() {
    return(
        <>
        <Helmet>
        <title>ProMedic Blog - ¿A qué se refiere la NOM-030?</title>
        <meta name="description" content="La salud ocupacional se encarga de promover y mantener el más alto grado de bienestar físico, mental y social de todos los trabajadores." />
        </Helmet> 
        <div className="wrapper w-11/12 md:w-10/12 mx-auto my-5 md:my-16 md:flex md:justify-between">
            <div class="main w-full md:w-[60%]">
                <img src={post} alt="" />
                <h1 className="title text-3xl font-bold my-3">¿A qué se refiere la NOM-030?</h1>
                <p className="extract text-lg text-gray-700">La salud ocupacional se encarga de promover y mantener el más alto grado de bienestar físico, mental y social de todos los trabajadores.</p>
                <p className="body-post text-gray-700 mt-3">
                En Promedic, como líderes en salud ocupacional te damos a conocer información importante de la Norma Oficial Mexicana NOM-030-STPS-2009, (Servicios preventivos de seguridad y salud en el trabajo-Funciones y actividades). Su objetivo principal es prevenir accidentes y enfermedades laborales, estableciendo las funciones y/o actividades que deberán realizar las empresas.<br /><br />
                El campo de aplicación de esta norma corresponde a todo el territorio nacional y en todos los centros de trabajo. <br /><br />
                ¿Qué son los servicios preventivos de seguridad y salud?<br /><br />
                Son las actividades prestadas por el personal capacitado, cuyo propósito es prevenir los accidentes y enfermedades dentro del área de trabajo, todo esto con el cumplimiento de la normatividad en materia de seguridad y salud laboral establecida por las autoridades mexicanas. Es obligación de los patrones designar a un responsable de seguridad y salud en el trabajo, a partir de cien trabajadores ya sea interno, externo o mixto:
                <ol className="list-decimal pl-10">
                    <li>Externos: Personal capacitado que es ajeno al centro de trabajo.</li>
                    <li>Internos: Jefe / patrón o personal designado que es capacitado en el centro de trabajo.</li>
                    <li>Mixtos: Personal capacitado o personal designado en el centro de trabajo.</li>
                </ol>
                <br />
                El patrón debe contar con un diagnóstico integral de las condiciones de seguridad y salud del centro laboral, además de contar con programas definidos en el diagnóstico de seguridad y salud laboral con la finalidad de:
                <ol className="list-decimal pl-10">
                    <li>Identificar todos los riesgos asociados; En las actividades / Instalaciones / Equipo.</li>
                    <li>Evaluar todos los riesgos posibles.</li>
                    <li>Establecer las medidas pertinentes de control.</li>
                </ol>
                <br />
                También es importante mencionar que se deben analizar los puestos de trabajo y conocer los diferentes riesgos que presenta la empresa, y en caso de que amerite, de acuerdo con el resultado de estos análisis de riesgos, deberá proporcionar equipo de protección personal; tales como, zapatos de Seguridad, cascos, anteojos, guantes, Chalecos antirreflejantes, Tapones auditivos, mascarillas, etc. <br /><br />
                Asimismo, la empresa debe comunicar los riesgos a los que está expuesto el personal, tales como agentes físicos, químicos, biológicos, ergonómicos y psicosociales. <br /><br />
                El proceso de prevención de riesgos ocupacionales se desarrolla en tres etapas:
                <ol className="list-decimal pl-10">
                    <li>Reconocimiento e identificación de los agentes contaminantes.</li>
                    <li>Evaluación de los contaminantes.</li>
                    <li>Control, adopción de medidas.</li>
                </ol>
                <br />
                Hoy en día es imprescindible para las empresas contar con un programa de salud ocupacional, ya que además de salvaguardar la integridad física y emocional de los colaboradores, representa ahorros significativos para las organizaciones. Cualquier falta a la NOM-030-STPS-2009, se verá acreedor a una sanción que asciende de los $15,000.00 MXN hasta los $400,000.00 MXN, todo esto depende de las condiciones de la revisión. <br /><br />
                En Promedic cuidamos el bienestar de los trabajadores, con programas especializados en cada rama laboral, contáctanos para obtener asesoría y tu programa de Salud Ocupacional ideal. <br /><br />
                Conoce más sobre la NOM 035 <br /><br />
                Referencias: <br />
                <Link to="https://www.dof.gob.mx/normasOficiales/3923/stps/stps.htm">https://www.dof.gob.mx/normasOficiales/3923/stps/stps.htm</Link> <br /> 
                <Link to="https://www.eleconomista.com.mx/capitalhumano/STPS-endurece-reglas-y-reduce-plazos-para-la-inspeccion-de-las-empresas-20210615-0097.html">https://www.eleconomista.com.mx/capitalhumano/STPS-endurece-reglas-y-reduce-plazos-para-la-inspeccion-de-las-empresas-20210615-0097.html</Link>
                </p>
            </div>
            <div className="sidebar w-full md:w-[30%] mb-16 h-[25vh] mx-auto">
                <button className="text-right my-5 w-full text-green-900 text-xl border-red-600"><Link to="/">← Regresar a Inicio</Link></button>
                <h2 className="text-right text-xl font-bold">Artículos</h2>
                <ul className="my-3 text-green-800 text-lg text-right leading-8">
                    <li>
                        <Link to="/cultura-salud-ocupacional" className="hover:font-bold ease-out duration-120">Cultura en Salud Ocupacional</Link>
                    </li>
                    <li>
                        <Link to="/nom-30" className="hover:font-bold ease-out duration-120">¿A qué se refiere la NOM-030?</Link>
                    </li>
                    <li>
                        <Link to="/factores-riesgo-psicosocial" className="hover:font-bold ease-out duration-120">¿Qué son los factores de riesgo psicosocial?</Link>
                    </li>
                    <li>
                        <Link to="/principales-accidentes-en-trabajo" className="hover:font-bold ease-out duration-120">¿Cuáles son los principales accidentes en el trabajo?</Link>
                    </li>
                </ul>
            </div>
        </div>
        </>
    )
}