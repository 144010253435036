import Slider from "../components/Slider"
import SliderLogos from "../components/SliderLogos"
import ExpedienteSection from "../components/Expediente"
import CardsHome from "../components/HomeCards"
import FormHomeMobile from "../components/FormHomeMobile"
import Posts from "../components/Posts"
import banner from "../assets/img/general-banner-promedic.png"
import { Helmet } from "react-helmet"

export default function Home() {

    return(
        <>  
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Equipo multidisciplinario con expertos en diferentes áreas de salud ocupacional, miembros activos de las principales sociedades en el campo y certificados en estándares internacionales, en actualización permanente en área clínica, tecnológica y seguridad, lo que nos permite integrar todos nuestros servicios orientados en el máximo beneficio de nuestros clientes y sus trabajadores." />
                <title>ProMedic - Empresas</title>
                <link rel="canonical" href="https://promedic.com.mx/empresas" />
            </Helmet>
            <img className="w-full" src={banner} alt="" />
            <FormHomeMobile />
            <Slider />
            <ExpedienteSection />
            <SliderLogos />
            <CardsHome />
            <Posts />
        </>
    )
}