import badge from "../assets/img/BG.png"

export default function ServiciosComponent() {
    return(
        <>
            <div className="w-10/12 mx-auto">
                <h1 className="font-bold text-4xl my-3">Servicios ProMedic</h1>
                <div className="grid md:grid-cols-2">
                    <div className="md:pr-10">
                        <ul>
                            <li>
                                <img className="float-left mr-2" src={badge} alt="" /><p className="font-bold mb-1">Orientación Médica Ocupacional</p>
                                <p className="mb-3">En cumplimiento a normatividad aplicable y planeación de programas de salud con estándares internacionales.</p>
                            </li>
                            <li>
                                <img className="float-left mr-2" src={badge} alt="" /><p className="font-bold mb-1">Soluciones Tecnológicas</p>
                                <p className="mb-3">Aplicación web para gestión integral de servicio médico para toma de decisiones de alto impacto.</p>
                            </li>
                            <li>
                                <img className="float-left mr-2" src={badge} alt="" /><p className="font-bold mb-1">Laboratorio Clínico Certificado</p>
                                <p className="mb-3">Contamos con equipamiento propio para estudios de Imagenología, así como Laboratorios de proceso certificados con marcas reconocidas.</p>
                            </li>
                            <li>
                                <img className="float-left mr-2" src={badge} alt="" /><p className="font-bold mb-1">Profesionales de Salud en tu empresa</p>
                                <p className="mb-3">Cobertura con personal calificado acorde a tu necesidad.</p>
                            </li>
                            <li>
                                <img className="float-left mr-2" src={badge} alt="" /><p className="font-bold mb-2">Traslado Aéreo y Terrestre de Pacientes</p>
                                <p className="mb-3">Unidades de ambulancia con diferente equipamiento acorde a las necesidades de las empresas o bien para traslados, coberturas protegidas y eventos.</p>
                            </li>
                        </ul>
                    </div>
                    <div className="md:border-l-2 md:pl-10">
                        <ul>
                            <li>
                                <img className="float-left mr-2" src={badge} alt="" /><p className="font-bold mb-1">Unidades Médicas Móviles</p>
                                <p className="mb-3">Exámenes ocupacionales y atención médica en tus instalaciones o donde lo requieras a través de unidades móviles.</p>
                            </li>
                            <li>
                                <img className="float-left mr-2" src={badge} alt="" /><p className="font-bold mb-1">Centros de Atención Médica</p>
                                <p className="mb-3">Atención médica y exámenes ocupacionales con capacidad para valoraciones masivas.</p>
                            </li>
                            <li>
                                <img className="float-left mr-2" src={badge} alt="" /><p className="font-bold mb-2">Atención Hospitalaria y Especialistas</p>
                                <p className="mb-3">Atención por especialistas médicos con experiencia en salud ocupacional y hospitales convenio</p>
                            </li>
                            <li>
                                <img className="float-left mr-2" src={badge} alt="" /><p className="font-bold mb-1">Venta de insumos y equipamiento para enfermería</p>
                                <p className="mb-3">Venta de biológicos para campañas de vacunación.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}